import React from 'react';

import './ComingSoon.css';
import pick from './pick.png';
function ComingSoon() {

    return (
        <div className='body-div div-div '>
            <h1 className="text1">COMING SOON</h1>
            <h3 className="uc">Under Construction!</h3>
        </div>
    );
}

export default ComingSoon;
