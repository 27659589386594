import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

function List() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { file, category, company } = useParams();
    let description, category_data;
    description = require(`../../JSON/${company.toUpperCase()}/${file}`);
    category_data = description[category];
    return (
        <div className='List text-center'>
            <h4 className='mx-auto my-3 ' style={{ fontWeight: '600' }}>
                {category.replaceAll('_', ' ')}
            </h4>

            <div class='table-responsive'>
                <table class='table table-striped text-left '>
                    <thead>
                        <tr>
                            <th scope='col'>Product Number</th>
                            <th scope='col'>Product Line</th>
                            <th scope='col'>Product Line Description</th>
                            <th scope='col'>Item Group</th>
                        </tr>
                    </thead>
                    <tbody>
                        {category_data.map((value, index) => (
                            <tr>
                                <th scope='row'>{value.PNumber}</th>
                                <td>{value.PL}</td>
                                <td>{value.PSDesc}</td>
                                <td>{value.IGroup}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default List;
