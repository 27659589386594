import React, { Component } from 'react';
import Textarea from '../../Textarea/Textarea';
import items from '../../../JSON/ProjectDescription.json';
import Footer from '../../Footer/Footer';
import Navbar from '../../Navbar/Navbar';
import ProjectCarousel from '../../Projects/ProjectCarousel';

class ProductionDescription extends Component {
    constructor(props) {
        super(props);
        const ids = parseInt(this.props.match.params.id);
        this.state = {
            selected: items.projects.find((item) => item.id === ids),
        };
    }
    render() {
        return (
            <div>
                <Navbar />

                <Textarea
                    Title={this.state.selected.Name}
                    Description={this.state.selected.Description}
                />
                <ProjectCarousel ids={this.props.match.params.id} />
                <Footer />
            </div>
        );
    }
}

export default ProductionDescription;
