import React from 'react';
import { Link } from 'react-router-dom';

function SearchResults({ name, src, file, category, categoryName, company }) {
    return (
        <Link to={`/products/${company}/${file}/${category}`}>
            <div
                className='row mb-1'
                style={{
                    overflowY: 'visible',
                    fontWeight: '500',
                    listStyleType: 'none',
                }}>
                <div
                    className='col-3 text-left ml-3 '
                    style={{ paddingRight: '0', paddingLeft: '0' }}>
                    <img src={src} height={70} width={65} alt='searchResult' />
                </div>
                <div
                    className='col-6 text-left pt-2 ml-2'
                    style={{ paddingRight: '0', paddingLeft: '0' }}>
                    <span
                        className='name'
                        style={{
                            textTransform: 'capitalize',
                            fontWeight: '700',
                        }}>
                        {name.toLowerCase()}{' '}
                    </span>
                    <br />
                    <span
                        className='name'
                        style={{
                            textTransform: 'capitalize',
                        }}>
                        {categoryName}{' '}
                        <i className='fas fa-angle-double-right fa-lg ml-2 '></i>
                    </span>
                </div>
            </div>
        </Link>
    );
}

export default SearchResults;
