import React from 'react';

import './Projects.css';

import image1 from '../Images/Projects/1.1.png';
import image2 from '../Images/Projects/2.2.png';
import image3 from '../Images/Projects/3.3.png';
import image4 from '../Images/Projects/4.1.png';

function ProjectsMobile() {
    const aStyle = {
        color: 'black',
        fontWeight: '500',
    };

    return (
        <div>
            <div className='projects'>
                <div className='  d-block d-md-none '>
                    <div
                        className='row mb-5 '
                        style={{
                            backgroundColor: '#f8f9fa',
                            borderRadius: '5px',
                        }}>
                        <div className='col-md-4 col-lg-3 text-center my-auto'>
                            <div className=' mr-3 '>
                                <p
                                    className='mt-2'
                                    style={{ fontWeight: 700, color: 'black' }}>
                                    Automated Driving Test System (ADTS)
                                </p>
                                <p style={aStyle} className='text-justify'>
                                    ADTS is an electronic sensor-based
                                    technology to automatically evaluate the
                                    driving skills on a pre-determined track.
                                </p>
                                <a
                                    className='text-primary'
                                    href='/projects/1'
                                    style={{ fontWeight: '600' }}>
                                    Know More
                                </a>
                            </div>
                        </div>

                        <div className='col-md-8 col-lg-7 text-right'>
                            <div
                                className='hp mb-2'
                                style={{
                                    backgroundImage: `url(${image1})`,
                                }}>
                                {' '}
                            </div>
                        </div>
                    </div>

                    <div
                        className='row mb-5'
                        style={{
                            backgroundColor: '#f8f9fa',
                            borderRadius: '5px',
                        }}>
                        <div className='col-md-0 col-lg-1'></div>

                        <div className='col-md-8 col-lg-7  text-left '>
                            <div
                                className='hp mb-2'
                                style={{
                                    backgroundImage: `url(${image2})`,
                                }}>
                                {' '}
                            </div>
                        </div>

                        <div className='col-md-4 col-lg-3 text-center my-auto '>
                            <div className=' mr-3'>
                                <p
                                    className='mt-2'
                                    style={{ fontWeight: 700, color: 'black' }}>
                                    Swing/Flap Gate Solution
                                </p>
                                <p style={aStyle} className='text-justify'>
                                    Our product range in entrance
                                    controlsolutions include Tripod, Half
                                    height, ¾ Height,Full Height dual /single
                                    Lane, Clearstyle, Boombarrier, Retractable
                                    Flapbarrier etc.
                                </p>
                                <a
                                    className='text-primary'
                                    href='/projects/2'
                                    style={{ fontWeight: '600' }}>
                                    Know More
                                </a>
                            </div>
                        </div>
                    </div>

                    <div
                        className='row mb-5'
                        style={{
                            backgroundColor: '#f8f9fa',
                            borderRadius: '5px',
                        }}>
                        <div className='col-md-0 col-lg-1'></div>

                        <div className='col-md-4 col-lg-3 text-center my-auto'>
                            <div className=' mr-3'>
                                <p
                                    className='mt-2'
                                    style={{ fontWeight: 700, color: 'black' }}>
                                    Access Control System
                                </p>
                                <p style={aStyle} className='text-justify'>
                                    Access control system is one of the most
                                    common used system in electronic door
                                    control using a card or a magnetic stripe.
                                </p>
                                <a
                                    className='text-primary'
                                    href='/projects/3'
                                    style={{ fontWeight: '600' }}>
                                    Know More
                                </a>
                            </div>
                        </div>

                        <div className='col-md-8 col-lg-7 text-right '>
                            <div
                                className='hp mb-2'
                                style={{
                                    backgroundImage: `url(${image3})`,
                                }}></div>
                        </div>
                    </div>

                    <div
                        className='row mb-5'
                        style={{
                            backgroundColor: '#f8f9fa',
                            borderRadius: '5px',
                        }}>
                        <div className='col-md-0 col-lg-1'></div>

                        <div className='col-md-8 col-lg-7  text-left '>
                            <div
                                className='hp mb-2'
                                style={{
                                    backgroundImage: `url(${image4})`,
                                }}></div>
                        </div>
                        <div className='col-md-4 col-lg-3 text-center my-auto'>
                            <div className=' mr-3'>
                                <p
                                    className='mt-2'
                                    style={{ fontWeight: 700, color: 'black' }}>
                                    CCTV Surveillance System & Services
                                </p>
                                <p style={aStyle} className='text-justify'>
                                    High-performance managed service with
                                    maximum availability ,complement and enhance
                                    the security arrangements
                                </p>
                                <a
                                    className='text-primary'
                                    href='/projects/4'
                                    style={{ fontWeight: '600' }}>
                                    Know More
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProjectsMobile;
