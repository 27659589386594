import React from 'react';
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer';
import category from '../../../JSON/main.json';
import MainCategory from '../../MainCategory/MainCategory';

function MainCategoryScreen() {
    return (
        <div>
            <Navbar />
            <MainCategory category={category} />
            <Footer />
        </div>
    );
}

export default MainCategoryScreen;
