import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './ProductDescriptionCard.css';
import Checkbox from './Checkbox';

function ProductDescriptionCard({
    name,
    desc,
    handleCompare,
    src,
    itemValue,
    know_more,
    range,
}) {
    const [isChecked, setIsChecked] = useState(false);
    const [isShown, setIsShown] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };
    let { company } = useParams();
    let descriptions = desc.split(',');
    return (
        <div
            className='description text-center '
            style={{
                borderBottom: '1px solid black',
                borderRadius: '5px',
                boxShadow: '7px 7px 5px 0px rgba(0, 0, 0, 0.1)',
            }}>
            <div
                style={{
                    maxHeight: '250px',
                    minHeight: '250px',
                    backgroundImage: `linear-gradient( 130deg, rgba(209, 0, 36, 0.9) 0%, rgba(209, 0, 36, 0.9) 3.5%, transparent 3.5% ),url(${src})`,
                    backgroundSize: 'contain ',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                }}></div>

            <div
                className='container mb-3'
                style={{
                    borderRadius: '5px',
                }}>
                <p
                    style={{
                        minHeight: '48px',
                        marginBottom: '0',
                    }}>
                    {name}
                </p>
                {range === undefined ? null : (
                    <p className='text-danger'>{range}</p>
                )}

                <p className='text-left text-primary'>Product Description</p>
                {descriptions.length > 1 ? (
                    <div
                        style={{
                            maxHeight: '150px',
                            minHeight: '150px',
                            overflowX: 'scroll',
                        }}>
                        {descriptions.map((value, index) => (
                            <div className='row justify-content-start'>
                                <div className='col-1 text-left'>
                                    <i
                                        className='fas fa-circle '
                                        style={{ fontSize: '0.25rem' }}></i>
                                </div>
                                <div className='col-10 text-left'>
                                    <p className='text-left' key={index}>
                                        {value}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div
                        style={{
                            maxHeight: '150px',
                            minHeight: '150px',
                            overflowX: 'scroll',
                            textAlign: 'left',
                        }}>
                        No Description Available
                    </div>
                )}
                <div className='form-check text-left mt-3'>
                    <div className='row'>
                        <label
                            className='col-5 my-auto'
                            style={{
                                borderRadius: 5,
                                border: isShown
                                    ? '1px solid transparent'
                                    : '1px solid black',
                                padding: 5,
                                backgroundColor: isShown ? '#007bff' : 'white',
                                color: isShown ? 'white' : 'black',
                                cursor: isShown ? 'pointer' : 'default',
                            }}
                            onMouseEnter={() => setIsShown(true)}
                            onMouseLeave={() => setIsShown(false)}>
                            <Checkbox
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                onClick={() => handleCompare(itemValue)}
                            />
                            <span style={{ marginLeft: 5 }}>+ Compare</span>
                        </label>

                        <div className='col-1'></div>
                        <label
                            className=' col-5  my-auto'
                            style={{
                                borderRadius: 5,
                                border: '1px solid black',
                                padding: 5,
                            }}>
                            <i
                                className='fas fa-phone fa-lg '
                                style={{ color: '#28a745' }}></i>
                            <a
                                href='tel:+918023207629'
                                style={{
                                    textDecoration: 'none',
                                    paddingLeft: 20,
                                }}>
                                <span className='  name'>Call Us</span>
                            </a>
                        </label>
                    </div>
                </div>
            </div>
            <div className='  pr-2 pb-1'>
                {/* {company !== 'hp' ? ( */}
                <div className=' text-right '>
                    <a href={`${know_more}`}>
                        <span className=' text-primary '>Know More</span>

                        <i
                            className='fas fa-angle-double-right fa-lg ml-2 mr-2'
                            style={{ color: '#007bff' }}></i>
                    </a>
                </div>
                {/* ) : null} */}
            </div>
        </div>
    );
}

export default ProductDescriptionCard;
