import React, { Component } from 'react';
import './Textarea.css';

class Textarea extends Component {
    render() {
        const { Description, Title } = this.props;
        return (
            <div id='TextArea' className='TextArea  d-block'>
                <div className='row m-0'>
                    <div className='col-md-11 p-0'>
                        <div className='container holder'>
                            <div
                                className='mainsection'
                                data-aos='zoom-in'
                                data-aos-duration='1000'>
                                <div className='header'>
                                    <div className='titele'>
                                        <h1
                                            style={{
                                                color: '#0791df',
                                            }}>
                                            {Title}{' '}
                                        </h1>
                                    </div>
                                </div>

                                {Description.map((value, index) => (
                                    <div className='subText'>
                                        <div className='subtextarea'>
                                            <div className='titele'>
                                                <h3
                                                    style={{
                                                        color: '#0791df',
                                                    }}>
                                                    {value.Heading}{' '}
                                                </h3>
                                            </div>
                                            {value.Content.map(
                                                (valueCon, index) => (
                                                    <p>{valueCon}</p>
                                                )
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* end of Container */}
                        </div>
                    </div>
                    <div className='col-md-1'></div>
                </div>
            </div>
        );
    }
}
export default Textarea;
