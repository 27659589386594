import React from 'react';
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer';
import Category from '../../Category/Category';

import category from '../../../JSON/main.json';

function ProductCategoryScreen() {
    return (
        <div>
            <Navbar />
            <Category category={category} />

            <Footer />
        </div>
    );
}

export default ProductCategoryScreen;
