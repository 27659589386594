import React from 'react';
import Navbar from '../../Navbar/Navbar';
import Company from '../../CompanyScreen/Company';

import Client from '../../Clients/Client';

import Footer from '../../Footer/Footer';

function FrontScreen() {
    return (
        <div>
            <Navbar />
            <Company />

            <Client />

            <Footer />
        </div>
    );
}

export default FrontScreen;
