import React from 'react';
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer';
import List from '../../List/List';

function TableScreen() {
    return (
        <div>
            <Navbar />
            <List />

            <Footer />
        </div>
    );
}

export default TableScreen;
