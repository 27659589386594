import React from 'react';
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer';

import SearchDescription from '../../Description/SearchDescription';

function SearchDescPage() {
    return (
        <div>
            <Navbar />
            <SearchDescription />

            <Footer />
        </div>
    );
}

export default SearchDescPage;
