import React from 'react';
import Navbar from '../../Navbar/Navbar';

import Footer from '../../Footer/Footer';
import Projects from '../../Projects/Projects';
import ProjectsMobile from '../../Projects/ProjectsMobile';

function PScreen() {
    return (
        <div>
            <Navbar />
            <Projects />
            <ProjectsMobile />

            <Footer />
        </div>
    );
}

export default PScreen;
