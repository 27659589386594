import React from 'react';
import './Services.css';
import image3 from '../../Images/wires.jpg';
import image1 from '../../Images/slaptop.jpg';
import image2 from '../../Images/rentals.jpg';

function Services() {
    const aStyle = {
        color: 'black',
        fontWeight: '500',
    };

    return (
        <div>
            <div className='projects'>
                <div className='  d-none d-md-block d-lg-block d-xl-block '>
                    <div className='row mb-5 '>
                        <div className='col-md-0 col-lg-1'></div>
                        <div className='col-md-4 col-lg-3 text-center my-auto'>
                            <div className=' mr-3 '>
                                <h5
                                    className='mt-2'
                                    style={{ fontWeight: 700, color: 'black' }}>
                                    Rentals
                                </h5>
                                <p style={aStyle} className='text-justify'>
                                    You do not want to invest in the Hardware
                                    new, you can talk to us. We provide you the
                                    Hardware for which you can pay every month.
                                    This will include repairs for any breakdowns
                                    during the rental period. All Consumables
                                    required will be provided by us. Use the
                                    equipment hassle free. Call today and ask
                                    for the best rates.
                                </p>
                            </div>
                        </div>

                        <div className='col-md-8 col-lg-7 text-center '>
                            <div
                                className='hp mb-2'
                                style={{
                                    backgroundImage: `url(${image2})`,
                                    backgroundPosition: 'center left',
                                    backgroundSize: 'cover',
                                }}></div>
                            <p
                                className='mx-auto'
                                style={{ color: 'black', fontWeight: '500' }}>
                                And Other Products For Your Office Needs!
                            </p>
                        </div>
                    </div>

                    <div className='row mb-5'>
                        <div className='col-md-0 col-lg-1'></div>

                        <div className='col-md-8 col-lg-7  text-left '>
                            <div
                                className='serv mb-2'
                                style={{
                                    backgroundImage: `url(${image1})`,
                                }}></div>
                        </div>

                        <div className='col-md-4 col-lg-3 text-center my-auto '>
                            <div className=' mr-3'>
                                <h5
                                    className='mt-2'
                                    style={{ fontWeight: 700, color: 'black' }}>
                                    Warranty Post Sales
                                </h5>
                                <p style={aStyle} className='text-justify'>
                                    Do you want tension free usage of the
                                    Hardware you are using.Call Us for
                                    maintenance. We provide Carepacks, AMC, on
                                    call services. The early you transfer the
                                    maintenance hardware to us, the lesser you
                                    pay.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-5 '>
                        <div className='col-md-0 col-lg-1'></div>

                        <div className='col-md-4 col-lg-3 text-center my-auto'>
                            <div className=' mr-3'>
                                <h5
                                    className='mt-2'
                                    style={{ fontWeight: 700, color: 'black' }}>
                                    Wiring And Cabling
                                </h5>
                                <p style={aStyle} className='text-justify'>
                                    We also provide services for Networking,
                                    Electrical wiring for power backups and
                                    camera surveillance networks.
                                </p>
                            </div>
                        </div>

                        <div className='col-md-8 col-lg-7 text-right '>
                            <div
                                className='mb-2 serv'
                                style={{
                                    backgroundImage: `url(${image3})`,
                                }}></div>
                        </div>
                    </div>
                    <div className='mx-auto text-center w-50'>
                        <a
                            href='tel:+918023207629'
                            style={{ textDecoration: 'none' }}>
                            <button
                                type='button'
                                className='btn btn-primary btn-lg  btn-block '>
                                Contact Us
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;
