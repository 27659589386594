import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ProductCategoryScreen from './Components/Screens/ProductCategoryScreen/ProductCategoryScreen';
import FrontScreen from './Components/Screens/FrontScreen/FrontScreen';
import ProductDescPage from './Components/Screens/ProductDescScreen/ProductDescPage';
import ContactScreen from './Components/Screens/ContactScreen/ContactScreen';
import CompareScreen from './Components/Screens/ProductCompareScreen/CompareScreen';
import SearchDescPage from './Components/Screens/SearchResultScreen/SearchDescPage';
import ProjectDescription from './Components/Screens/ProjectDescription/ProjectDescription';

import './App.css';
import About from './Components/About/About';

import MainCategoryScreen from './Components/Screens/ProductCategoryScreen/MainCategoryScreen';
import PScreen from './Components/Screens/ProjectsScreen/PScreen';

import CategoryOtherScreen from './Components/Screens/CategoryOtherScreen/CategoryOtherScreen';
import TableScreen from './Components/Screens/ProductDescScreen/TableScreen';
import ServicesScreen from './Components/Screens/ServicesScreen/ServicesScreen';
import ComingSoonScreen from './Components/Screens/ComingSoonScreen/ComingSoonScreen';

function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path='/' exact component={FrontScreen} />
                <Route path='/contactus' exact component={ContactScreen} />
                <Route path='/aboutus' exact component={About} />
                <Route path='/projects' exact component={PScreen} />
                <Route
                    path='/projects/:id'
                    exact
                    component={ProjectDescription}
                />
                <Route
                    path='/service/:cat/:sub_cat/:file'
                    exact
                    component={ProductCategoryScreen}
                />
                <Route
                    path='/category/:cat'
                    exact
                    component={MainCategoryScreen}
                />
                <Route
                    path='/products/:company/:file/:category'
                    exact
                    component={ProductDescPage}
                />
                <Route
                    path='/products/:searchValue'
                    exact
                    component={SearchDescPage}
                />
                <Route path='/compare' exact component={CompareScreen} />
                <Route
                    path='/list/:company/:file/:category'
                    exact
                    component={TableScreen}
                />

                <Route
                    path='/details/:cat/:sub_cat/:file'
                    exact
                    component={CategoryOtherScreen}
                />

                <Route path='/services' exact component={ServicesScreen} />
                <Route path='/offerspage' exact component={ComingSoonScreen} />
                <Route path='/buynow' exact component={ComingSoonScreen} />
            </Switch>
        </BrowserRouter>
    );
}

export default App;
