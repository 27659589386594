import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import img1 from '../Images/ADTS/1.1.png';
import img2 from '../Images/ADTS/1.2.png';
import img3 from '../Images/ADTS/1.4.png';
import img4 from '../Images/ADTS/1.5.png';
import img5 from '../Images/ADTS/1.3.png';
import img6 from '../Images/ADTS/1.6.png';
import img7 from '../Images/ADTS/1.7.png';
import img8 from '../Images/ADTS/1.8.png';
import img9 from '../Images/ADTS/1.9.png';

import img10 from '../Images/SwingGateSolution/2.1.png';
import img11 from '../Images/SwingGateSolution/2.2.png';
import img12 from '../Images/SwingGateSolution/2.3.png';

import img13 from '../Images/AccessControlSystem/3.1.png';
import img14 from '../Images/AccessControlSystem/3.3.png';
import img15 from '../Images/AccessControlSystem/3.3.png';

import img16 from '../Images/CCTV/4.1.png';

function ProjectCarousel({ ids }) {
    return (
        <div className='container mx-auto mt-2'>
            <div className='row mx-auto'>
                <div className='col-md-6 col-12  mx-auto'>
                    {parseInt(ids) === 1 ? (
                        <Carousel fade={true}>
                            <Carousel.Item
                                style={{
                                    height: '40vh',
                                    backgroundImage: `url(${img1})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            />
                            <Carousel.Item
                                style={{
                                    height: '40vh',
                                    backgroundImage: `url(${img2})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            />
                            <Carousel.Item
                                style={{
                                    height: '40vh',
                                    backgroundImage: `url(${img3})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            />
                            <Carousel.Item
                                style={{
                                    height: '40vh',
                                    backgroundImage: `url(${img4})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            />
                            <Carousel.Item
                                style={{
                                    height: '40vh',
                                    backgroundImage: `url(${img5})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            />
                            <Carousel.Item
                                style={{
                                    height: '40vh',
                                    backgroundImage: `url(${img6})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            />
                            <Carousel.Item
                                style={{
                                    height: '40vh',
                                    backgroundImage: `url(${img7})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            />
                            <Carousel.Item
                                style={{
                                    height: '40vh',
                                    backgroundImage: `url(${img8})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            />
                            <Carousel.Item
                                style={{
                                    height: '40vh',
                                    backgroundImage: `url(${img9})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            />
                        </Carousel>
                    ) : parseInt(ids) === 2 ? (
                        <Carousel fade={true}>
                            <Carousel.Item
                                style={{
                                    height: '40vh',
                                    backgroundImage: `url(${img10})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            />
                            <Carousel.Item
                                style={{
                                    height: '40vh',
                                    backgroundImage: `url(${img11})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            />
                            <Carousel.Item
                                style={{
                                    height: '40vh',
                                    backgroundImage: `url(${img12})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            />
                        </Carousel>
                    ) : parseInt(ids) === 3 ? (
                        <Carousel fade={true}>
                            <Carousel.Item
                                style={{
                                    height: '40vh',
                                    backgroundImage: `url(${img13})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            />
                            <Carousel.Item
                                style={{
                                    height: '40vh',
                                    backgroundImage: `url(${img14})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            />
                            <Carousel.Item
                                style={{
                                    height: '40vh',
                                    backgroundImage: `url(${img15})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            />
                        </Carousel>
                    ) : (
                        <Carousel fade={true}>
                            <Carousel.Item
                                style={{
                                    height: '40vh',
                                    backgroundImage: `url(${img16})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            />
                        </Carousel>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ProjectCarousel;
