import React from 'react';
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer';
import Description from '../../Description/Description';

function ProductDescPage() {
    return (
        <div>
            <Navbar />
            <Description />

            <Footer />
        </div>
    );
}

export default ProductDescPage;
