import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

import cascadelogo from '../Images/cascadelogo.png';
function Footer() {
    return (
        <footer
            className='footer pt-10  mt-5 '
            style={{
                borderTop: '1.5px solid #a2acba',
                borderRadius: '10px',
                backgroundColor: '#616C6F',
                color: '#EAF0F1',
            }}>
            <div className=' mt-3'>
                <div className='row'>
                    <div className='col-lg-4 ' style={{ textAlign: 'center' }}>
                        <Link to='/'>
                            <img
                                src={cascadelogo}
                                height={100}
                                alt='cascadelogo'
                            />
                        </Link>
                        <div
                            className='footer-brand mt-3'
                            style={{ textSize: '16px' }}>
                            Cascade Systems And Communication Pvt Ltd
                        </div>
                        <div className='mb-3 '></div>
                        <div className='icon-list-social mb-1'>
                            <i className='fab fa-facebook-f fa-lg m-3' />

                            <i className='fab fa-twitter fa-lg m-3' />
                            <i className='fab fa-linkedin-in fa-lg m-3' />
                        </div>
                    </div>
                    <div className='col-lg-8'>
                        <div className='row'>
                            <div className='col-lg-3 col-md-6 mb-4 mb-lg-0'>
                                <div
                                    className=' mb-4'
                                    style={{ textTransform: 'uppercase' }}>
                                    Contact Us
                                </div>
                                <ul className='list-unstyled mb-0'>
                                    <li className='mb-2'>#917 42nd Cross,</li>
                                    <li className='mb-2'>
                                        3rd Block, Rajajinagar,
                                    </li>
                                    <li className='mb-2'>Bangalore, 560010</li>
                                    <li className='mb-2'>
                                        <a
                                            href='tel:+918023207629'
                                            style={{
                                                textDecoration: 'none',
                                                color: 'white',
                                            }}>
                                            +918023207629
                                        </a>
                                    </li>

                                    <li>
                                        <a
                                            href='mailto: sales@cascadesystem.in'
                                            style={{
                                                textDecoration: 'none',
                                                color: 'white',
                                            }}>
                                            sales@cascadesystems.in
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-lg-3 col-md-6 mb-4 mb-lg-0'>
                                <div
                                    className='text-uppercase-expanded  mb-4'
                                    style={{ textTransform: 'uppercase' }}>
                                    Companies
                                </div>
                                <ul className='list-unstyled mb-0'>
                                    <li className='mb-2'>
                                        <Link to='/category/hp'>
                                            Hewlett Packard(HP)
                                        </Link>
                                    </li>
                                    <li className='mb-2'>
                                        <Link to='/products/amraraja/amraraja/amraraja'>
                                            Amararaja Batteries
                                        </Link>
                                    </li>
                                    <li className='mb-2'>
                                        <Link to='/category/tvs'>
                                            TVS Electronics
                                        </Link>
                                    </li>
                                    <li className='mb-2'>
                                        <Link to='/category/vertiv'>
                                            Vertiv
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                            <div className='col-lg-3 col-md-6 mb-4'>
                                <div
                                    className='text-uppercase-expanded  mb-4'
                                    style={{ textTransform: 'uppercase' }}>
                                    Coming Soon
                                </div>
                                <ul className='list-unstyled mb-0'>
                                    <li className='mb-2'>
                                        <Link to='/buynow'>Buy Now</Link>
                                    </li>
                                    <li className='mb-2'>
                                        <Link to='/offerspage'>
                                            Offers Page
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-lg-3 col-md-6 mb-4 mb-md-0 '>
                                <div
                                    className='text-uppercase-expanded  mb-4'
                                    style={{ textTransform: 'uppercase' }}>
                                    Links
                                </div>
                                <ul className='list-unstyled mb-0'>
                                    <li className='mb-2'>
                                        <Link to='/'> Home</Link>
                                    </li>
                                    <li className='mb-2'>
                                        <Link to='/services'>Services</Link>
                                    </li>
                                    <li className='mb-2'>
                                        <Link to='/projects'>Projects</Link>
                                    </li>
                                    <li className='mb-2'>
                                        <Link to='/aboutus'>About Us</Link>
                                    </li>
                                    <li className='mb-2'>
                                        <Link to='/contactUs'>Contact Us</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row align-items-center'>
                    <div className='col-md-12 mx-auto text-center my-2'>
                        Copyright © Cascade Systems And Communication Pvt Ltd
                        2020
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
