import React from 'react';
import Navbar from '../../Navbar/Navbar';
import ComingSoon from '../../ComingSoon/ComingSoon';
import Footer from '../../Footer/Footer';


function ComingSoonScreen() {
    return (
        <div>
            <Navbar />
            <ComingSoon />
            <Footer />
        </div>
    );
}

export default ComingSoonScreen;
