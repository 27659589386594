import React, { useEffect } from 'react';
import CategoryCard from '../CategoryCard/CategoryCard';
import { useParams } from 'react-router-dom';

import './Category.css';
function Category({ category }) {
    let { cat, sub_cat, file } = useParams();
    let cat_data = category[cat];
    let sub_data = cat_data.filter((cat_data) => cat_data.name === sub_cat);
    let sub_data_cat = sub_data[0].category;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='Category'>
            <div className='container mx-auto'>
                {sub_data_cat.map((value, index) => (
                    <div key={index}>
                        <h5
                            className='mb-3 ml-3'
                            style={{ textTransform: 'capitalize' }}>
                            {sub_data_cat.length !== 1
                                ? `Based on ${value.name}`
                                : null}
                        </h5>
                        <div className='row mx-auto'>
                            {value.subcategory.map((value, index) => (
                                <div
                                    className='col-md-6 col-lg-4 col-xl-4 mb-4'
                                    key={index}>
                                    <CategoryCard
                                        name={value.name}
                                        knowMore={value.link}
                                        src={value.Image_src}
                                        file={file}
                                        company={cat}
                                        from={cat}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Category;
