import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Link, useLocation } from 'react-router-dom';
import prod from '../../JSON/search.json';
import ProductDescriptionCard from '../CategoryDescripionCard/ProductDescriptionCard';
import 'react-toastify/dist/ReactToastify.css';

function SearchDescription() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const searchValue = location.state.searchValue;
    const inputValue = searchValue.trim().toLowerCase();
    const inputLength = inputValue.length;
    let category_data =
        inputLength === 0
            ? []
            : prod.products.filter(
                (lang) =>
                    lang.Product_link.toLowerCase().includes(inputValue)

            );

    const [compareList, setCompareList] = useState([]);
    const handleCompare = (id) => {
        toast.dismiss();

        if (compareList.indexOf(id) !== -1) {
            setCompareList((compareList) => [
                ...compareList.filter((uid) => uid !== id),
            ]);
        } else {
            setCompareList((compareList) => [...compareList, id]);
        }
    };

    //Creative ToastNotifications
    const MsgSuccess = () => (
        <div>
            <Link
                to={{
                    pathname: '/compare',
                    state: {
                        compareList: compareList,
                    },
                }}>
                <i className='fas fa-balance-scale text-success mr-3'></i>
                <span style={{ fontFamily: 'Montserrat' }}>
                    Go to Compare Screen
                </span>
            </Link>
        </div>
    );
    const MsgAddMore = () => (
        <div>
            <i
                className='fas fa-balance-scale  mr-3'
                style={{ color: '#007bff' }}></i>
            <span
                style={{
                    fontFamily: 'Montserrat',
                    fontWeight: '500',
                    color: '#a2acba',
                }}>
                Select one more Product
            </span>
        </div>
    );
    const MsgFail = () => (
        <div className='text-center'>
            <i className='fas fa-balance-scale text-danger mr-3'></i>
            <span
                style={{
                    fontFamily: 'Montserrat',
                    fontWeight: '500',
                    color: '#a2acba',
                }}>
                Cannot Compare more than 3 Products
            </span>
        </div>
    );
    //Toast Notifications Done

    const Notify = (compareList) => {
        if (compareList.length === 1) {
            toast(<MsgAddMore />);
        } else if (compareList.length > 1 && compareList.length <= 3) {
            toast(<MsgSuccess />);
        } else if (compareList.length > 3) {
            toast(<MsgFail />);
        }
    };

    return (
        <div className='container '>
            <ToastContainer closeOnClick={false} limit={2} autoClose={false} />
            {Notify(compareList)}
            {category_data.length !== 0 ? (
                <div className='row'>
                    {category_data.map((value, index) => (
                        <div
                            className='col-md-6 col-lg-4 col-xl-4 mb-4'
                            key={index}>
                            <ProductDescriptionCard
                                itemValue={value}
                                name={value.Product_link}
                                desc={value.desc}
                                src={value.Image_src}
                                id={value.Sku_Number}
                                handleCompare={handleCompare}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                    <div
                        style={{
                            fontFamily: 'Montserrat',
                            fontWeight: '500',
                            color: '#a2acba',
                            textAlign: 'center',
                            minHeight: '60vh'
                        }}>
                        <h3>No Result Found!</h3>
                    </div>
                )}
        </div>
    );
}

export default SearchDescription;
