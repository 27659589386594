import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import hplogo from '../Images/hpl.png';
import logo from '../Images/vert.png';
import tvslogo from '../Images/tvslogo.png';
import amlogo from '../Images/amlogo.png';
import hpbackground from '../Images/hpbackground.jpg';
import tvs from '../Images/tvs.png';
import vertiv from '../Images/vertiv.jpg';
import amararaja from '../Images/amararaja.jpg';
import testImage from '../Images/testing.jpg';
import ama1 from '../Images/amarajatran/3.1.png';
import ama2 from '../Images/amarajatran/3.2.png';
import ama3 from '../Images/amarajatran/3.3.png';
import ama4 from '../Images/amarajatran/3.4.png';
import ama5 from '../Images/amarajatran/3.5.png';
import ama6 from '../Images/amarajatran/3.6.png';
import ama7 from '../Images/amarajatran/3.7.png';
import ama8 from '../Images/amarajatran/3.8.png';
import ama9 from '../Images/amarajatran/3.9.png';
import ama10 from '../Images/amarajatran/3.91.png';
import ver1 from '../Images/vertivtran/4.1.png';
import ver2 from '../Images/vertivtran/4.2.png';
import ver3 from '../Images/vertivtran/4.3.png';

import './Company.css';
function CompanyDefault() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [indexHp, setIndexHp] = useState(0);

    const handleSelectHp = (selectedIndex, e) => {
        setIndexHp(selectedIndex);
    };
    const handleNextSelectHp = (selectedIndex, e) => {
        if (indexHp !== 6) setIndexHp(indexHp + 1);
        else setIndexHp(0);
    };
    const handlePrevSelectHp = (selectedIndex, e) => {
        if (indexHp !== 0) setIndexHp(indexHp - 1);
        else setIndexHp(6);
    };
    const [indexAmararaja, setIndexAmararaja] = useState(0);

    const handleSelectAmararaja = (selectedIndex, e) => {
        setIndexAmararaja(selectedIndex);
    };
    const handleNextSelectAmararaja = (selectedIndex, e) => {
        if (indexAmararaja !== 9) setIndexAmararaja(indexAmararaja + 1);
        else setIndexAmararaja(0);
    };
    const handlePrevSelectAmararaja = (selectedIndex, e) => {
        if (indexAmararaja !== 0) setIndexAmararaja(indexAmararaja - 1);
        else setIndexAmararaja(9);
    };
    const [indexVertiv, setIndexVertiv] = useState(0);

    const handleSelectVertiv = (selectedIndex, e) => {
        setIndexVertiv(selectedIndex);
    };

    const handleNextSelectVertiv = (selectedIndex, e) => {
        if (indexVertiv !== 2) setIndexVertiv(indexVertiv + 1);
        else setIndexVertiv(0);
    };
    const handlePrevSelectVertiv = (selectedIndex, e) => {
        if (indexVertiv !== 0) setIndexVertiv(indexVertiv - 1);
        else setIndexVertiv(2);
    };

    const [indexTvs, setIndexTvs] = useState(0);

    const handleSelectTvs = (selectedIndex, e) => {
        setIndexTvs(selectedIndex);
    };

    const handleNextSelectTvs = (selectedIndex, e) => {
        if (indexTvs !== 8) setIndexTvs(indexTvs + 1);
        else setIndexTvs(0);
    };
    const handlePrevSelectTvs = (selectedIndex, e) => {
        if (indexTvs !== 0) setIndexTvs(indexTvs - 1);
        else setIndexTvs(8);
    };

    return (
        <div className=' d-none d-md-block d-lg-block d-xl-block '>
            {/* Hp */}

            <div className='container text-center mx-auto mb-1'>
                <h1
                    className='text-3d mb-5'
                    data-text='One Place For All Your '
                    style={{
                        fontWeight: 900,
                    }}>
                    <p className='mb-2'>We Value</p>
                    Innovation, Quality & Trust
                </h1>
            </div>

            <div className='row mb-5 '>
                <div className='col-md-0 col-lg-1'></div>
                <div className='col-md-12 col-lg-10 shabox px-0'>
                    <div
                        className='row'
                        style={{
                            backgroundImage: `linear-gradient(to right bottom,rgba(255,255,255,0.5),rgba(255,255,255,0.75)),url(${testImage})`,
                            backgroundSize: 'cover',
                            backgroundAttachment: 'fixed',
                        }}>
                        <div className='col-md-7 col-lg-7  text-left py-2'>
                            <Carousel fade={true}>
                                <Carousel.Item>
                                    <Link to='/details/others/allprinters/allprinters'>
                                        <div
                                            className='hp_main '
                                            style={{
                                                backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/c/0/c04894793.png)`,
                                            }}></div>
                                    </Link>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Link to='/details/others/allprinters/allprinters'>
                                        <div
                                            className='hp_main '
                                            style={{
                                                backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/Y/5/Y5S54A-1_T1561014105.png)`,
                                            }}></div>
                                    </Link>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Link to='/details/others/allprinters/allprinters'>
                                        <div
                                            className='hp_main '
                                            style={{
                                                backgroundImage: `url(https://www.tvs-e.in/wp-content/uploads/2019/12/RP-3220-STAR.png)`,
                                            }}></div>
                                    </Link>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Link to='/details/others/allprinters/allprinters'>
                                        <div
                                            className='hp_main '
                                            style={{
                                                backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/G/1/G1W39A-1_T1539229999.png)`,
                                            }}></div>
                                    </Link>
                                </Carousel.Item>

                                <Carousel.Item>
                                    <Link to='/details/others/allprinters/allprinters'>
                                        <div
                                            className='hp_main '
                                            style={{
                                                backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/Z/6/Z6Z13A-1_T1539232253.png
)`,
                                            }}></div>
                                    </Link>
                                </Carousel.Item>

                                <Carousel.Item>
                                    <Link to='/details/others/allprinters/allprinters'>
                                        <div
                                            className='hp_main '
                                            style={{
                                                backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/L/2/L2759A-1_T1539228482.png)`,
                                            }}></div>
                                    </Link>
                                </Carousel.Item>
                            </Carousel>
                            <div className='container text-center mt-1'>
                                <div className='row text-center align-items-center'>
                                    <div className='col text-center'>
                                        <Link to='/details/others/allprinters/allprinters'>
                                            <h3
                                                style={{
                                                    color: 'black',
                                                    fontWeight: '800',
                                                    cursor: 'pointer',
                                                }}>
                                                Printers
                                            </h3>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5 col-lg-5  text-right py-2'>
                            <Carousel fade={true}>
                                <Carousel.Item>
                                    <Link to='/details/others/allcatridges/allcatridges'>
                                        <div
                                            className='hp_main '
                                            style={{
                                                backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/h/p/hp_682_tri-color_3ym76aa_front_view.png)`,
                                            }}></div>
                                    </Link>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Link to='/details/others/allcatridges/allcatridges'>
                                        <div
                                            className='hp_main '
                                            style={{
                                                backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/L/0/L0S54AA-1_T1539225732.png)`,
                                            }}></div>
                                    </Link>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Link to='/details/others/allcatridges/allcatridges'>
                                        <div
                                            className='hp_main '
                                            style={{
                                                backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/C/E/CE278AF-1_T1539224510.png)`,
                                            }}></div>
                                    </Link>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Link to='/details/others/allcatridges/allcatridges'>
                                        <div
                                            className='hp_main '
                                            style={{
                                                backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/W/1/W1103AD-1_T1564128832.png)`,
                                            }}></div>
                                    </Link>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Link to='/details/others/allcatridges/allcatridges'>
                                        <div
                                            className='hp_main '
                                            style={{
                                                backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/X/4/X4E78AA-1_T1539227928.png)`,
                                            }}></div>
                                    </Link>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Link to='/details/others/allcatridges/allcatridges'>
                                        <div
                                            className='hp_main '
                                            style={{
                                                backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/1/V/1VV21AA-1_T1563523556.png)`,
                                            }}></div>
                                    </Link>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Link to='/details/others/allcatridges/allcatridges'>
                                        <div
                                            className='hp_main '
                                            style={{
                                                backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/C/E/CE506A-1_T1539224566.png
)`,
                                            }}></div>
                                    </Link>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Link to='/details/others/allcatridges/allcatridges'>
                                        <div
                                            className='hp_main '
                                            style={{
                                                backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/S/U/SU825A-1_T1550112858.png)`,
                                            }}></div>
                                    </Link>
                                </Carousel.Item>
                            </Carousel>
                            <div className='container text-center mt-1'>
                                <div className='row text-center align-items-center'>
                                    <div className='col text-center'>
                                        <Link to='/details/others/allcatridges/allcatridges'>
                                            <h3
                                                style={{
                                                    color: 'black',
                                                    fontWeight: '800',
                                                    cursor: 'pointer',
                                                }}>
                                                Cartridges
                                            </h3>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mb-5'>
                <div className='col-md-0 col-lg-1'></div>
                <div className='col-md-12 col-lg-10 shabox px-0'>
                    <div
                        className='row'
                        style={{
                            backgroundImage: `linear-gradient(to right bottom,rgba(255,255,255,0.5),rgba(255,255,255,0.75)),url(${hpbackground})`,
                            backgroundSize: 'cover',
                            backgroundAttachment: 'fixed',
                        }}>
                        <div className='col-md-4 col-lg-4 text-center my-auto '>
                            <div className='container'>
                                <Link to='/category/hp'>
                                    <img
                                        src={hplogo}
                                        alt='logo'
                                        style={{
                                            height: '200px',
                                            marginTop: '10px',
                                        }}
                                    />
                                    <br /> <br />
                                    <span style={{ color: '#0098e0' }}>
                                        Explore All
                                    </span>
                                </Link>
                            </div>
                        </div>

                        <div className='col-md-8 col-lg-8  text-right '>
                            <Carousel
                                activeIndex={indexHp}
                                onSelect={handleSelectHp}
                                fade={true}>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/C/E/CE655A-1_T1539224582.png)`,
                                        }}></div>
                                    <div className='container text-center'>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectHp
                                                    }>
                                                    Cartridges
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/service/hp/PRINTERS/hp_printers'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Printers
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectHp
                                                    }>
                                                    Scanners
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/L/2/L2741A-1_T1539225754.png)`,
                                        }}></div>
                                    <div className='container text-center'>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectHp
                                                    }>
                                                    Printers
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='products/hp/hp_printers/hp_printers_scanners'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Scanners
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectHp
                                                    }>
                                                    Accesories
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/2/V/2VN99AA-1_T1542593902.png)`,
                                        }}></div>

                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectHp
                                                    }>
                                                    Printers
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/service/hp/ACCESSORIES/hp_accessories'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Accesories
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectHp
                                                    }>
                                                    Monitors
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/1/f/1fh47a7_1.png)`,
                                        }}></div>
                                    <div className='container text-center'>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectHp
                                                    }>
                                                    Accesories
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/service/hp/MONITORS/hp_monitors'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Monitors
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectHp
                                                    }>
                                                    Personal Laptops
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/1/4/14-dh1008tu_1.png)`,
                                        }}></div>
                                    <div className='container text-center'>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectHp
                                                    }>
                                                    Monitors
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/service/hp/PERSONAL_LAPTOPS/hp_personal_laptops'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Personal Laptops
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectHp
                                                    }>
                                                    Desktops
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>

                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/5/Q/5QC71AA-1_T1593164604.png)`,
                                        }}></div>
                                    <div className='container text-center'>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectHp
                                                    }>
                                                    Personal Laptops
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/service/hp/DESKTOPS/hp_desktops'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Desktops
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectHp
                                                    }>
                                                    Business Laptops
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/9/K/9KW89PA-1_T1586423594.png)`,
                                        }}></div>
                                    <div className='container text-center'>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectHp
                                                    }>
                                                    Desktops
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/service/hp/BUSINESS_LAPTOPS/hp_bussiness_laptop'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Business Laptops
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectHp
                                                    }>
                                                    Catridges
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>

                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/L/0/L0S23AA-1_T1539225721.png)`,
                                        }}></div>
                                    <div className='container text-center'>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectHp
                                                    }>
                                                    Business Laptops
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/service/hp/CARTRIDGES/hp_cartridges'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Catridges
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectHp
                                                    }>
                                                    Printers
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>

            {/* Amararaja */}
            <div className='row mb-5 '>
                <div className='col-md-0 col-lg-1'></div>
                <div className='col-md-12 col-lg-10 shabox px-0'>
                    <div
                        className='row'
                        style={{
                            backgroundImage: `linear-gradient(to right bottom,rgba(255,255,255,0.5),rgba(255,255,255,0.75)),url(${amararaja})`,
                            backgroundSize: 'cover',
                            backgroundAttachment: 'fixed',
                        }}>
                        <div className='col-md-4 col-lg-4 text-center my-auto '>
                            <div className='container'>
                                <Link to='/products/amraraja/amraraja/amraraja'>
                                    <img
                                        src={amlogo}
                                        alt='logo'
                                        style={{
                                            height: '80px',
                                            marginTop: '10px',
                                        }}
                                    />
                                    <br /> <br />
                                    <span style={{ color: '#0098e0' }}>
                                        Explore All
                                    </span>
                                </Link>
                            </div>
                        </div>
                        <div className='col-md-8 col-lg-8  text-left '>
                            <Carousel
                                activeIndex={indexAmararaja}
                                onSelect={handleSelectAmararaja}
                                fade={true}>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(${ama1})`,
                                        }}></div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectAmararaja
                                                    }>
                                                    Amaron Quanta-HUPS
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/products/amraraja/amraraja/amraraja'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Amaron Quanta-HWS
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectAmararaja
                                                    }>
                                                    Amaron Brute
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(${ama2})`,
                                        }}></div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectAmararaja
                                                    }>
                                                    Amaron Quanta-HWS
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/products/amraraja/amraraja/amraraja'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Amaron Brute
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectAmararaja
                                                    }>
                                                    Amaron Volt
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(${ama3})`,
                                        }}></div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectAmararaja
                                                    }>
                                                    Amaron Brute
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/products/amraraja/amraraja/amraraja'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Amaron Volt
                                                    </h5>
                                                </Link>
                                            </div>

                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectAmararaja
                                                    }>
                                                    PowerStack
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(${ama4})`,
                                        }}></div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectAmararaja
                                                    }>
                                                    Amaron Volt
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/products/amraraja/amraraja/amraraja'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        PowerStack
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectAmararaja
                                                    }>
                                                    Genpro
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(${ama5})`,
                                        }}></div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectAmararaja
                                                    }>
                                                    PowerStack
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/products/amraraja/amraraja/amraraja'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Genpro
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectAmararaja
                                                    }>
                                                    Amaron Solar
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(${ama6})`,
                                        }}></div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectAmararaja
                                                    }>
                                                    Genpro
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/products/amraraja/amraraja/amraraja'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Amaron Solar
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectAmararaja
                                                    }>
                                                    Amaron Sleek
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(${ama7})`,
                                        }}></div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectAmararaja
                                                    }>
                                                    Amaron Solar
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/products/amraraja/amraraja/amraraja'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Amaron Sleek
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectAmararaja
                                                    }>
                                                    Amaron Quanta
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(${ama8})`,
                                        }}></div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectAmararaja
                                                    }>
                                                    Amaron Sleek
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/products/amraraja/amraraja/amraraja'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Amaron Quanta
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectAmararaja
                                                    }>
                                                    Amaron Quanta XEL
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>

                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(${ama9})`,
                                        }}></div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectAmararaja
                                                    }>
                                                    Amaron Quanta
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/products/amraraja/amraraja/amraraja'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Amaron Quanta XEL
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectAmararaja
                                                    }>
                                                    Amaron Quanta-HUPS
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(${ama10})`,
                                        }}></div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectAmararaja
                                                    }>
                                                    Amaron Quanta XEL
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/products/amraraja/amraraja/amraraja'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Amaron QuantaHUPS
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectAmararaja
                                                    }>
                                                    Amaron Quanta-HWS
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>

            {/* Vertiv */}
            <div className='row mb-5 '>
                <div className='col-md-0 col-lg-1'></div>
                <div className='col-md-12 col-lg-10 shabox px-0'>
                    <div
                        className='row'
                        style={{
                            backgroundImage: `linear-gradient(to right bottom,rgba(255,255,255,0.5),rgba(255,255,255,0.75)),url(${vertiv})`,
                            backgroundSize: 'cover',
                            backgroundAttachment: 'fixed',
                        }}>
                        <div className='col-md-4 col-lg-4 text-center my-auto '>
                            <div className='container'>
                                <Link to='/category/vertiv'>
                                    <img
                                        src={logo}
                                        alt='logo'
                                        style={{
                                            height: '200px',
                                            marginTop: '10px',
                                        }}
                                    />
                                    <br />
                                    <span style={{ color: '#0098e0' }}>
                                        Explore All
                                    </span>
                                </Link>
                            </div>
                        </div>
                        <div className='col-md-8 col-lg-8 text-right '>
                            <Carousel
                                activeIndex={indexVertiv}
                                onSelect={handleSelectVertiv}
                                fade={true}>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(${ver1})`,
                                        }}></div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectVertiv
                                                    }>
                                                    Rack & Enclosures
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/service/vertiv/CRITICAL_POWER/critical_power'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Critical Power
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectVertiv
                                                    }>
                                                    Thermal Management
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(${ver2})`,
                                        }}></div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectVertiv
                                                    }>
                                                    Critical Power
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/service/vertiv/THERMAL_MANAGEMENT/thermal_management'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Thermal Management
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectVertiv
                                                    }>
                                                    Rack & Enclosures
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(${ver3})`,
                                        }}></div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectVertiv
                                                    }>
                                                    Thermal Management
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/service/vertiv/RACKS_&_ENCLOSURES/racks_and_enclosures'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Rack & Enclosures
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectVertiv
                                                    }>
                                                    Critical Power
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>

            {/* Tvs */}
            <div className='row mb-5 '>
                <div className='col-md-0 col-lg-1'></div>
                <div className='col-md-12 col-lg-10 shabox px-0'>
                    <div
                        className='row'
                        style={{
                            backgroundImage: `linear-gradient(to right bottom,rgba(255,255,255,0.5),rgba(255,255,255,0.75)),url(${tvs})`,
                            backgroundSize: 'cover',
                            backgroundAttachment: 'fixed',
                        }}>
                        <div className='col-md-4 col-lg-4 text-center my-auto '>
                            <div className='container'>
                                <Link to='/category/tvs'>
                                    <img
                                        src={tvslogo}
                                        alt='logo'
                                        style={{
                                            height: '150px',
                                            marginTop: '10px',
                                        }}
                                    />
                                    <br /> <br />
                                    <span style={{ color: '#0098e0' }}>
                                        Explore All
                                    </span>
                                </Link>
                            </div>
                        </div>
                        <div className='col-md-8 col-lg-7  text-left '>
                            <Carousel
                                activeIndex={indexTvs}
                                onSelect={handleSelectTvs}
                                fade={true}>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(https://www.tvs-e.in/wp-content/uploads/2019/12/MSP-250-STAR-300x203.png)`,
                                        }}></div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectTvs
                                                    }>
                                                    POS Systems
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/service/tvs/PRINTERS/tvs_printers'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Printers
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectTvs
                                                    }>
                                                    Barcode Scanners
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(https://www.tvs-e.in/wp-content/uploads/2019/12/BS-L100.png)`,
                                        }}></div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectTvs
                                                    }>
                                                    Printers
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/products/tvs/barcode_scanners/barcode_scanners'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Barcode Scanners
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectTvs
                                                    }>
                                                    E-Cash Registers
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(https://www.tvs-e.in/wp-content/uploads/2019/12/PT3124.png)`,
                                        }}></div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectTvs
                                                    }>
                                                    Barcode Scanners
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/products/tvs/cashregisters/cashregisters'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        E-Cash Registers
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectTvs
                                                    }>
                                                    Cash Drawers
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(https://www.tvs-e.in/wp-content/uploads/2020/01/Cash-Drawer-5-notes-768x541.png)`,
                                        }}></div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectTvs
                                                    }>
                                                    E-Cash Registers
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/products/tvs/cash_drawers/cash_drawers'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Cash Drawers
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectTvs
                                                    }>
                                                    OEM Models
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(https://www.tvs-e.in/wp-content/uploads/2020/02/DG-150S-300x196.png)`,
                                        }}></div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectTvs
                                                    }>
                                                    Cash Drawers
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/products/tvs/oem_models/oem_models'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        OEM Models
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectTvs
                                                    }>
                                                    POS DMP
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(https://www.tvs-e.in/wp-content/uploads/2019/12/RP-45-SHOPPE.png)`,
                                        }}></div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectTvs
                                                    }>
                                                    OEM Models
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/products/tvs/pos_dmp/pos_dmp'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        POS DMP
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectTvs
                                                    }>
                                                    Cash Counters
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>

                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(https://www.tvs-e.in/wp-content/uploads/2020/03/Asset-134mdpi-300x283.png)`,
                                        }}></div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectTvs
                                                    }>
                                                    POS DMP
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/products/tvs/cash_counters/cash_counters'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Cash Counters
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectTvs
                                                    }>
                                                    Keyboards & Mice
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(https://www.tvs-e.in/wp-content/uploads/2019/12/gold-300x147.png)`,
                                        }}></div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectTvs
                                                    }>
                                                    Cash Counters
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/products/tvs/keyboards/keyboards'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Keyboards & Mice
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectTvs
                                                    }>
                                                    Touch POS Systems
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(https://www.tvs-e.in/wp-content/uploads/2019/11/C4515-STAR-FEATURES-300x203.png)`,
                                        }}></div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handlePrevSelectTvs
                                                    }>
                                                    Keyboards & Mice
                                                </h6>
                                            </div>
                                            <div className='col text-center'>
                                                <Link to='/products/tvs/touch_pos_systems/touch_pos_systems'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        POS Systems
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className='col text-center'>
                                                <h6
                                                    style={{
                                                        color: 'black',
                                                        fontWeight: '700',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleNextSelectTvs
                                                    }>
                                                    Printers
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompanyDefault;
