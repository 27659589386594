import React, { useEffect } from 'react';
import CategoryCard from '../CategoryCard/CategoryCard';
import { useParams } from 'react-router-dom';

// import './Category.css';
function CategoryOther({ category }) {
    let { cat, sub_cat, file } = useParams();
    let cat_data = category[sub_cat];
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='Category'>
            <div className='container mx-auto'>
                {console.log(cat_data, file, sub_cat)}

                <div>
                    <div className='row mx-auto'>
                        {cat_data.map((value, index) => (
                            <div
                                className='col-md-6 col-lg-4 col-xl-4 mb-4'
                                key={index}>
                                <CategoryCard
                                    name={value.dname}
                                    knowMore={value.link}
                                    src={value.Image_src}
                                    file={file}
                                    company={cat}
                                    from={value.from}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CategoryOther;
