import React from 'react';
import Navbar from '../../Navbar/Navbar';
import Services from '../../Services/Projects/Services';
import Footer from '../../Footer/Footer';
import ServicesMobile from '../../Services/Projects/ServicesMobile';

function ServicesScreen() {
    return (
        <div>
            <Navbar />
            <Services />
            <ServicesMobile />
            <Footer />
        </div>
    );
}

export default ServicesScreen;
