import React from 'react';
import './Company.css';
import CompanyDefault from './CompanyDefault';
import CompanySmallScreen from './CompanySmallScreen';

function Company() {
    return (
        <div className='company'>
            <CompanyDefault />
            <CompanySmallScreen />
        </div>
    );
}

export default Company;
