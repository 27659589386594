import React from 'react';
import './CategoryCard.css';
import { Link } from 'react-router-dom';

function MainCategoryCard({ name, company, src, link }) {
    return (
        <div
            className='customCard p-1'
            style={{
                borderRadius: '5px',
                backgroundImage: `linear-gradient(
            130deg,
             rgba(0, 152, 224, 0.75) 0%,
             rgba(0, 152, 224, 0.75) 55%,
            rgba(228, 231, 237, 0.25) 55%
        ),url(${src})`,
            }}>
            <h5
                className='m-3 '
                style={{
                    fontWeight: '600',
                    textTransform: 'capitalize',
                }}>
                {name.toLowerCase()}
            </h5>
            <h6 className='m-3' style={{ fontWeight: '600' }}>
                From {company.toUpperCase()}
            </h6>
            <h6 className='m-3 mt-4' style={{ fontWeight: '500' }}>
                <Link
                    className='explore_now'
                    to={{
                        pathname: `${link}`,
                    }}>
                    Explore Now
                    <i className='fas fa-angle-double-right fa-lg ml-2'></i>
                </Link>
            </h6>
        </div>
    );
}

export default MainCategoryCard;
