import React from 'react';
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer';

import Compare from '../../Compare/Compare';

function CompareScreen(props) {

    const { compareList } = props.location.state;
    return (
        <div>
            <Navbar />
            <Compare compareList={compareList} />

            <Footer />
        </div>
    );
}

export default CompareScreen;
