import React from 'react';
import './client.css';
import log16 from '../Images/g5.png';
import log5 from '../Images/1.5.png';
import log8 from '../Images/g3.png';
import log9 from '../Images/g4.png';
import log10 from '../Images/g7.png';
import log12 from '../Images/1.93.jpg';
import log13 from '../Images/1.94.png';
import log14 from '../Images/1.95.jpg';
import log15 from '../Images/g6.png';
import log2 from '../Images/1.97.jpg';
import log3 from '../Images/1.98.png';
import log18 from '../Images/1.99.png';
import log19 from '../Images/g2.png';

function Client() {
    return (
        <div>
            <div
                className='clients '
                style={{
                    border: '1px solid white',
                }}>
                <h3 className='text-center ' style={{ fontWeight: '600' }}>
                    Our Clients
                </h3>
                <div
                    className='d-flex  flex-row bd-highlight mb-3'
                    style={{
                        scrollBehavior: 'smooth',
                        overflowX: 'scroll',
                        whiteSpace: 'nowrap',
                        border: '1px solid white',
                    }}>
                    <div className='p-2 bd-highlight'>
                        <img
                            src={log2}
                            style={{ borderRadius: '50%' }}
                            height={110}
                            className='m-3'
                            alt='logo'
                        />
                    </div>
                    <div className='p-2 bd-highlight'>
                        <img
                            src={log3}
                            style={{ borderRadius: '50%' }}
                            height={110}
                            className='m-3'
                            alt='logo'
                        />
                    </div>

                    <div className='p-2 bd-highlight'>
                        <img
                            src={log5}
                            style={{ borderRadius: '50%' }}
                            height={110}
                            className='m-3'
                            alt='logo'
                        />
                    </div>

                    <div className='p-2 bd-highlight'>
                        <img
                            src={log8}
                            style={{ borderRadius: '50%' }}
                            height={110}
                            className='m-3'
                            alt='logo'
                        />
                    </div>

                    <div className='p-2 bd-highlight'>
                        <img
                            src={log9}
                            style={{ borderRadius: '50%' }}
                            height={110}
                            className='m-3'
                            alt='logo'
                        />
                    </div>

                    <div className='p-2 bd-highlight'>
                        <img
                            src={log10}
                            style={{ borderRadius: '50%' }}
                            height={110}
                            className='m-3'
                            alt='logo'
                        />
                    </div>

                    <div className='p-2 bd-highlight'>
                        <img
                            src={log12}
                            style={{ borderRadius: '50%' }}
                            height={110}
                            className='m-3'
                            alt='logo'
                        />
                    </div>
                    <div className='p-2 bd-highlight'>
                        <img
                            src={log13}
                            style={{ borderRadius: '50%' }}
                            height={110}
                            className='m-3'
                            alt='logo'
                        />
                    </div>
                    <div className='p-2 bd-highlight'>
                        <img
                            src={log14}
                            style={{ borderRadius: '50%' }}
                            height={110}
                            className='m-3'
                            alt='logo'
                        />
                    </div>
                    <div className='p-2 bd-highlight'>
                        <img
                            src={log15}
                            style={{ borderRadius: '50%' }}
                            height={110}
                            className='m-3'
                            alt='logo'
                        />
                    </div>
                    <div className='p-2 bd-highlight'>
                        <img
                            src={log16}
                            style={{ borderRadius: '50%' }}
                            height={110}
                            className='m-3'
                            alt='logo'
                        />
                    </div>
                    <div className='p-2 bd-highlight'>
                        <img
                            src={log19}
                            style={{ borderRadius: '50%' }}
                            height={110}
                            className='m-3'
                            alt='logo'
                        />
                    </div>
                    <div className='p-2 bd-highlight'>
                        <img
                            src={log18}
                            style={{ borderRadius: '50%' }}
                            height={110}
                            className='m-3'
                            alt='logo'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Client;
