import React, { useEffect } from 'react';
import CompareCard from './CompareCard';

function Compare({ compareList }) {
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    return (
        <div>
            <div className='container d-none d-md-block mx-auto'>
                <div className='row mx-auto'>
                    {compareList.map((value, index) => (
                        <div
                            className='col-md-6 col-lg-4 col-xl-4 mb-4'
                            key={index}>
                            <CompareCard
                                itemValue={value}
                                name={value.Product_link}
                                know_more={value.know_more}
                                desc={value.desc}
                                src={value.Image_src}
                            />
                        </div>
                    ))}
                </div>
            </div>

            <div className='container d-md-none mx-auto'>
                <div
                    id='carouselExampleControls'
                    className='carousel slide'
                    data-ride='carousel'>
                    <div className='carousel-inner mx-auto'>
                        {compareList.map((value, index) => (
                            <div
                                className={
                                    index === 0
                                        ? 'carousel-item active'
                                        : 'carousel-item'
                                }
                                key={index}>
                                <CompareCard
                                    itemValue={value}
                                    name={value.Product_link}
                                    desc={value.desc}
                                    know_more={value.know_more}
                                    src={value.Image_src}
                                />
                            </div>
                        ))}
                    </div>
                    <a
                        className='carousel-control-prev'
                        href='#carouselExampleControls'
                        role='button'
                        data-slide='prev'>
                        <i
                            className='fas fa-angle-double-left'
                            style={{ color: '#007bff' }}></i>
                        <span className='sr-only'>Previous</span>
                    </a>
                    <a
                        className='carousel-control-next'
                        href='#carouselExampleControls'
                        role='button'
                        data-slide='next'>
                        <i
                            className='fas fa-angle-double-right'
                            style={{ color: '#007bff' }}></i>
                        <span className='sr-only'>Next</span>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Compare;
