import React from 'react';
import Navbar from '../../Navbar/Navbar';
import ContactUs from '../../Contact Us/ContactUs';
import Footer from '../../Footer/Footer';


function ContactScreen() {
    return (
        <div>
            <Navbar />
            <ContactUs />

            <Footer />
        </div>
    );
}

export default ContactScreen;
