import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Autosuggest from 'react-autosuggest';
import cat from '../../JSON/searchCategory.json';
import { Link, useHistory } from 'react-router-dom';
import cascade from '../Images/cascade.png';
import SearchResults from '../SearchResult/SearchResults';

import './Navbar.css';

const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const result =
        inputLength === 0
            ? []
            : cat.categories.filter(
                (lang) =>
                    lang.categoryName.toLowerCase().includes(inputValue) ||
                    lang.name.toLowerCase().includes(inputValue)
            );
    return result;
};

const getSuggestionValue = (suggestion) => suggestion.name;

const renderSuggestion = (suggestion) => (
    <SearchResults
        name={suggestion.name}
        src={suggestion.Product_Images}
        file={suggestion.categoryLink}
        category={suggestion.subCategoryName}
        categoryName={suggestion.categoryName}
        company={suggestion.company}
    />
);

function Navbar() {
    let location = useLocation();
    const history = useHistory();
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const onChange = (event, { newValue }) => {
        setValue(newValue);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        history.push({
            pathname: `/products/${value}`,
            state: { searchValue: value },
        });
    };
    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value));
    };
    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };
    const renderInputComponent = (inputProps) => (
        <form className='form-inline my-2 my-lg-0 ' onSubmit={handleSubmit}>
            <div className='input-group input-group-lg  input-group-lg mb-3'>
                <input
                    style={{
                        borderRadius: '7px',
                    }}
                    {...inputProps}
                    className='form-control mr-3'
                    type='search'
                    placeholder='Search Products'
                    aria-label='Search'
                />

                <div className='input-group-append'>
                    <button
                        className='btn btn-outline-success btn-lg mb-2 my-sm-0'
                        type='submit'
                        onClick={handleSubmit}
                        style={{ borderRadius: '5px' }}>
                        Search
                    </button>
                </div>
            </div>
        </form>
    );
    const inputProps = {
        value,
        onChange: onChange,
        className: 'form-control mr-sm-2',
    };
    return (
        <div className='Navbar mb-4'>
            <div className='container mt-2' style={{ paddingRight: '0' }}>
                <Link to='/'>
                    <img
                        src={cascade}
                        alt='Logo'
                        style={{ width: '100%', height: '100%' }}
                    />
                </Link>
            </div>
            <div
                className='container'
                style={{
                    paddingRight: '10px',
                    borderBottom: '0.05px solid black',
                    borderRadius: '5px',
                }}>
                <nav
                    className='navbar navbar-expand-lg navbar-light '
                    style={{ paddingRight: 0 }}>
                    <button
                        className='navbar-toggler ml-auto'
                        type='button'
                        data-toggle='collapse'
                        data-target='#navbarSupportedContent'
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'>
                        <span className='navbar-toggler-icon'></span>
                    </button>

                    <div
                        className='collapse navbar-collapse '
                        id='navbarSupportedContent'>
                        <ul
                            className='navbar-nav mr-auto'
                            style={{ fontSize: 18 }}>
                            <li className='nav-item'>
                                <Link to='/' className='nav-link'>
                                    <span
                                        className={
                                            location.pathname === '/'
                                                ? 'active'
                                                : 'test'
                                        }>
                                        Home
                                    </span>
                                    <span className='sr-only'>(current)</span>
                                </Link>
                            </li>
                            <li className='nav-item '>
                                <Link to='/services' className='nav-link'>
                                    <span
                                        className={
                                            location.pathname === '/services'
                                                ? 'active'
                                                : 'test'
                                        }>
                                        Services
                                    </span>
                                    <span className='sr-only'>(current)</span>
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link to='/projects' className='nav-link'>
                                    <span
                                        className={
                                            location.pathname === '/projects'
                                                ? 'active'
                                                : 'test'
                                        }>
                                        Projects
                                    </span>
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link to='/aboutus' className='nav-link'>
                                    <span
                                        className={
                                            location.pathname === '/aboutus'
                                                ? 'active'
                                                : 'test'
                                        }>
                                        About Us
                                    </span>
                                </Link>
                            </li>

                            <li class="nav-item dropdown">
                                <Link class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Coming Soon
                                </Link>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                    <Link to='/buynow' className='dropdown-item '>
                                        <span
                                            className={
                                                location.pathname === '/buynow'
                                                    ? 'active'
                                                    : 'test'
                                            }>
                                            Buy Now
                                    </span>
                                    </Link>
                                    <Link to='/offerspage' className='dropdown-item'>
                                        <span
                                            className={
                                                location.pathname === '/offerspage'
                                                    ? 'active'
                                                    : 'test'
                                            }>
                                            Offers Page
                                    </span>
                                    </Link>

                                </div>
                            </li>

                            <li className='nav-item'>
                                <Link to='/contactUs' className='nav-link'>
                                    <span
                                        className={
                                            location.pathname === '/contactUs'
                                                ? 'active'
                                                : 'test'
                                        }>
                                        Contact Us
                                    </span>
                                </Link>
                            </li>
                        </ul>

                        <Autosuggest
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={
                                onSuggestionsFetchRequested
                            }
                            onSuggestionsClearRequested={
                                onSuggestionsClearRequested
                            }
                            onChange={onChange}
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            inputProps={inputProps}
                            renderInputComponent={renderInputComponent}
                        />
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default Navbar;
