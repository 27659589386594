import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useParams, Link } from 'react-router-dom';
import ProductDescriptionCard from '../CategoryDescripionCard/ProductDescriptionCard';
import 'react-toastify/dist/ReactToastify.css';

function Description() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { file, category, company } = useParams();
    let description, category_data;
    description = require(`../../JSON/${company.toUpperCase()}/${file}`);
    category_data = description[category];
    const [compareList, setCompareList] = useState([]);
    const handleCompare = (id) => {
        toast.dismiss();
        if (compareList.indexOf(id) !== -1) {
            setCompareList((compareList) => [
                ...compareList.filter((uid) => uid !== id),
            ]);
        } else {
            setCompareList((compareList) => [...compareList, id]);
        }
    };

    //Creative ToastNotifications
    const MsgSuccess = () => (
        <div>
            <Link
                to={{
                    pathname: '/compare',
                    state: {
                        compareList: compareList,
                    },
                }}>
                <i className='fas fa-balance-scale text-success mr-3'></i>
                <span
                    style={{ fontFamily: 'Montserrat' }}
                    className='text-success'>
                    Go to Compare Screen
                </span>
            </Link>
        </div>
    );
    const MsgAddMore = () => (
        <div>
            <i
                className='fas fa-balance-scale  mr-3'
                style={{ color: '#007bff' }}></i>
            <span
                style={{
                    fontFamily: 'Montserrat',
                    fontWeight: '500',
                    color: '#a2acba',
                }}>
                Select one more Product
            </span>
        </div>
    );
    const MsgFail = () => (
        <div className='text-center'>
            <i className='fas fa-balance-scale text-danger mr-3'></i>
            <span
                style={{
                    fontFamily: 'Montserrat',
                    fontWeight: '500',
                    color: '#a2acba',
                }}>
                Cannot Compare more than 3 Products
            </span>
        </div>
    );
    //Toast Notifications Done

    const Notify = (compareList) => {
        if (compareList.length === 1) {
            toast(<MsgAddMore />);
        } else if (compareList.length > 1 && compareList.length <= 3) {
            toast(<MsgSuccess />);
        } else if (compareList.length > 3) {
            toast(<MsgFail />);
        }
    };

    return (
        <div className='container mx-auto'>
            <ToastContainer closeOnClick={false} limit={2} autoClose={false} />
            {Notify(compareList)}
            {category_data.length !== 0 ? (
                <div className='row'>
                    {category_data.map((value, index) => (
                        <div
                            className='col-md-6 col-lg-4 col-xl-4 mb-4'
                            key={index}>
                            <ProductDescriptionCard
                                itemValue={value}
                                name={value.Product_link}
                                desc={value.desc}
                                src={value.Image_src}
                                know_more={value.know_more}
                                range={value.range}
                                handleCompare={handleCompare}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <div
                    style={{
                        fontFamily: 'Montserrat',
                        fontWeight: '500',
                        color: '#a2acba',
                        textAlign: 'center',
                    }}>
                    <h3>No Result Found!</h3>
                </div>
            )}
        </div>
    );
}

export default Description;
