import React, { useEffect } from 'react';
import MainCategoryCard from '../MainCategoryCard/MainCategoryCard';
import { useParams } from 'react-router-dom';

import './MainCategory.css';
function MainCategory({ category }) {
    let { cat } = useParams();
    let cat_data = category[cat];
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='MainCategory'>
            {console.log(cat_data)}
            <div className='container'>
                <div className='row'>
                    {cat_data.map((value, index) => (
                        <div
                            className='col-md-6 col-lg-4 col-xl-4 mb-4'
                            key={index}>
                            <MainCategoryCard
                                name={value.name}
                                company={cat}
                                src={value.Image_src}
                                link={value.link}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default MainCategory;
