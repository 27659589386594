import React from 'react';

function CompareCard({ name, desc, know_more, src }) {
    let descriptions = desc.split(',');
    return (
        <div
            className='description text-center '
            style={{
                borderBottom: '1px solid black',
                borderRadius: '5px',
                boxShadow: '7px 7px 5px 0px rgba(0, 0, 0, 0.1)',

            }}>
            <div
                style={{
                    maxHeight: '250px',
                    minHeight: '250px',
                    backgroundImage: `linear-gradient( 130deg, rgba(209, 0, 36, 0.9) 0%, rgba(209, 0, 36, 0.9) 3.5%, transparent 3.5% ),url(${src})`,
                    backgroundSize: 'contain ',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                }}></div>

            <div className='container my-4 ' style={{
                borderRadius: '5px',
            }}>
                <p style={{
                    minHeight: '48px',

                }}>
                    {name}
                </p>
                <p className='text-left text-primary'>Product Description</p>
                {descriptions.length > 1 ? (
                    <div
                        style={{
                            maxHeight: '150px',
                            minHeight: '150px',
                            overflowX: 'scroll',
                        }}>
                        {descriptions.map((value, index) => (
                            <div className='row justify-content-start'>
                                <div className='col-1 text-left'>
                                    <i
                                        className='fas fa-circle '
                                        style={{ fontSize: '0.25rem' }}></i>
                                </div>
                                <div className='col-10 text-left'>
                                    <p className='text-left' key={index}>
                                        {value}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                        <div
                            style={{
                                maxHeight: '150px',
                                minHeight: '150px',
                                overflowX: 'scroll',
                                textAlign: 'left',
                            }}>
                            No Description Available
                        </div>
                    )}
            </div>
            <div className=' mt-2 p-2 '>
                <div className="row">
                    <label className=' text-left my-auto' style={{ borderRadius: 5, border: '2px solid black', padding: 5 }}>
                        <i
                            className='fas fa-phone fa-lg '
                            style={{ color: '#28a745' }}></i>
                        <a

                            href='tel:+918023207629'
                            style={{ textDecoration: 'none', paddingLeft: 15 }}>
                            <span className='  name'>Call Us</span>
                        </a>

                    </label>
                </div>
                <div className='text-right  '>
                    <a className="" href={`${know_more}`}>
                        <span className=' text-primary '>Know More</span>

                        <i
                            className='fas fa-angle-double-right fa-lg ml-2 mr-2'
                            style={{ color: '#007bff' }}></i>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default CompareCard;
