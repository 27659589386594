import React, { useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import * as emailjs from 'emailjs-com';
import './ContactUs.css';

function ContactUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const initialValues = {
        email: '',
        name: '',
        phone: '',
        subject: '',
        query: '',
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
        name: Yup.string(),
        phone: Yup.string(),
        subject: Yup.string(),
        query: Yup.string(),
    });
    const onSubmit = async (values, onSubmit) => {
        console.log(values);
        var template_params = {
            name: values.name,
            phone: values.phone,
            email: values.email,
            subject: values.subject,
            query: values.query,
        };
        var service_id = 'gmail';
        var template_id = 'template_wuvtorb';
        emailjs.send(
            service_id,
            template_id,
            template_params,
            'user_CnMhoIGKbIftmvcgVnWpw'
        );
        onSubmit.resetForm();
    };

    return (
        <div className='contactUs text-center pb-2 '>
            <h2 className='text-center mb-4' style={{ fontWeight: '800' }}>
                Get In Touch
            </h2>
            <div className='container'>
                <div className='row '>
                    <div className='col-md-7 text-left'>
                        <div>
                            <iframe
                                title='map'
                                style={{ borderRadius: '20px' }}
                                width='100%'
                                height='400'
                                frameBorder='0'
                                scrolling='no'
                                marginHeight='0'
                                marginWidth='0'
                                src='https://maps.google.com/maps?width=100%25&amp;height=100%&amp;hl=en&amp;q=Cascade%20Systems%20And%20Communication%20Pvt%20Ltd,%20917,%2042nd%20Cross%20Rd,%203rd%20Block,%20Rajajinagar,%20Bengaluru,%20Karnataka%20560010+(Cascade%20Systems)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'></iframe>
                        </div>
                        <h4 className='mt-3 mb-2' style={{ fontWeight: '600' }}>
                            Contact Us
                        </h4>
                        <span>#917 42nd Cross,</span>
                        <span> 3rd Block, Rajajinagar,</span>
                        <span>Bangalore, 560010</span>
                        <br />
                        <span
                            style={{
                                textDecoration: 'none',
                                color: 'black',
                                fontWeight: '600',
                            }}>
                            Email :
                            <a
                                className=' mx-1'
                                href='mailto: sales@cascadesystem.in'
                                style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                    fontSize: '0.9rem',
                                }}>
                                sales@cascadesystems.in
                            </a>
                        </span>{' '}
                        <br />
                        <span
                            className='my-5'
                            style={{
                                textDecoration: 'none',
                                color: 'black',
                                fontWeight: '600',
                            }}>
                            Phone :
                            <a
                                className=' mx-1'
                                href='tel:+918023207629'
                                style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                    fontSize: '0.9rem',
                                }}>
                                +91-8023207629
                            </a>
                            ,
                            <a
                                className=' mx-1'
                                href='tel:08023104102'
                                style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                    fontSize: '0.9rem',
                                }}>
                                080-23104102
                            </a>
                            ,
                            <a
                                className=' mx-1'
                                href='tel:08023382617'
                                style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                    fontSize: '0.9rem',
                                }}>
                                080-23382617
                            </a>
                        </span>
                    </div>

                    <div className='col-md-5 my-5'>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}>
                            {({ errors, values }) => (
                                <Form>
                                    <div className='form-row my-3'>
                                        <div className='col-7'>
                                            <Field
                                                type='text'
                                                className='form-control form-control-lg'
                                                style={{ fontSize: '1rem' }}
                                                placeholder='Name'
                                                name='name'
                                            />
                                        </div>

                                        <div className='col-5'>
                                            <Field
                                                type='text'
                                                className='form-control form-control-lg'
                                                style={{ fontSize: '1rem' }}
                                                placeholder='Phone Number'
                                                name='phone'
                                            />
                                        </div>
                                    </div>
                                    <div className='form-group mb-3'>
                                        <div className='input-group mb-4 '>
                                            {errors.email || !values.email ? (
                                                <div className='input-group-prepend'>
                                                    <span className='input-group-text'>
                                                        <i className='far fa-envelope text-primary'></i>
                                                    </span>
                                                </div>
                                            ) : (
                                                <div className='input-group-prepend'>
                                                    <span className='input-group-text text-success '>
                                                        <i className='far fa-envelope-open text-success'></i>
                                                    </span>
                                                </div>
                                            )}
                                            <Field
                                                type='email'
                                                name='email'
                                                className='form-control form-control-lg'
                                                style={{
                                                    fontSize: '1rem',
                                                    borderRight: 'none',
                                                }}
                                                placeholder='Enter Your Email'
                                            />
                                            {values.email.length === 0 ? (
                                                <div className='input-group-append '>
                                                    <span className='input-group-text '></span>
                                                </div>
                                            ) : values.email.length > 0 &&
                                              errors.email ? (
                                                <div className='input-group-append '>
                                                    <span className='input-group-text text-danger'>
                                                        <i className='fas fa-times  '></i>
                                                    </span>
                                                </div>
                                            ) : (
                                                <div className='input-group-append '>
                                                    <span className='input-group-text text-success '>
                                                        <i className='fas fa-check'></i>
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='form-group mb-3'>
                                        <Field
                                            type='text'
                                            className='form-control form-control-lg'
                                            style={{ fontSize: '1rem' }}
                                            placeholder='Subject'
                                            name='subject'
                                        />
                                    </div>
                                    <div className='form-group mb-5'>
                                        <Field
                                            className='form-control form-control-lg'
                                            style={{ fontSize: '1rem' }}
                                            id='exampleFormControlTextarea1'
                                            rows='3'
                                            name='query'
                                            placeholder='What do u want to know?'></Field>
                                    </div>
                                    <div className='text-center'>
                                        <button
                                            type='submit'
                                            className='btn btn-primary '
                                            style={{
                                                width: '100%',
                                                textTransform: 'uppercase',
                                                letterSpacing: '5',
                                                fontWeight: '500',
                                            }}>
                                            Submit
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            <br />
        </div>
    );
}

export default ContactUs;
