import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

import keerthi from '../Images/keerthi.jpg';
import awards from '../Images/awards.jpg';

function About() {
    return (
        <div>
            <Navbar />
            <div
                className='container text-center mx-auto p-2'
                style={{ fontFamily: 'Montserrat', fontWeight: '500' }}>
                <h3 style={{ fontWeight: '700' }}>About Us</h3>

                <p
                    className='text-justify'
                    style={{ wordSpacing: '2px', lineHeight: '250%' }}>
                    We are in the business of IT Products & services from 1986
                    offering Branded Products through stock & sale operations.
                    We represent companies like
                    <span className='text-primary'> TVS Electronics </span>
                    from last 25 years,
                    <span className='text-primary'>M/s Amaraja Batteries </span>
                    from last 15 years,
                    <span className='text-primary'> M/s HP India </span> from
                    last 13 years &
                    <span className='text-primary'> M/s Vertiv </span> from last
                    4 years for offering their products to our buyers. We reach
                    out to our customers and provide best of services .They may
                    be Defence Organizations, Government Institutions, Public
                    Sector, Industries, Education Institutions, Hospitals and
                    Distribution channel Networks.
                </p>
                <div className='d-flex justify-content-center'>
                    <img
                        alt='awards'
                        className='mx-auto'
                        src={awards}
                        style={{
                            width: '70%',
                            borderRadius: '10px',
                        }}
                    />
                </div>
                <div className='text-center'>
                    <h4
                        className='text-primary mt-1'
                        style={{ fontWeight: '800' }}>
                        Awards
                    </h4>
                </div>
                <p
                    className='text-justify'
                    style={{ wordSpacing: '2px', lineHeight: '250%' }}>
                    We also cater to organizations having
                    <span className='text-primary'> PAN India </span> presence
                    with supply Installation & warranty Maintenance We provide
                    Installation, Service backup, AMC’s and Rentals for all
                    products offered by us. Our Infrastructure Comprises of
                    Sales, Services and Finance Team based out of Bangalore. Our
                    warehouse is around 5000 Square feet for holding stocks of
                    products offered by us and is suitably supported by a fleet
                    of vehicles for delivery. We also provide Automation,
                    Surveillance and Security Solutions. A few of them, which we
                    have implemented, are displayed in this website.
                    <br /> <br />
                </p>
                <div className='text-center'>
                    <h4 className='text-primary' style={{ fontWeight: '800' }}>
                        We are Led By Commitment and Driven By Excellence.
                    </h4>
                </div>

                <div className='row text-center mx-auto'>
                    <div className='d-flex col justify-content-center'>
                        <div
                            style={{
                                height: 200,
                                borderRadius: '50%',
                                width: 200,
                                backgroundImage: `url(${keerthi})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center',
                            }}
                        />
                    </div>
                </div>
                <br />
                <span style={{ fontWeight: '600' }}>Mr. Kirti Mehta</span>
            </div>
            <Footer />
        </div>
    );
}

export default About;
