import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import hplogo from '../Images/hpl.png';
import vmlogo from '../Images/vmlogo.png';
import tvslogo from '../Images/tvslogo.png';
import hpbackground from '../Images/hpbackground.jpg';
import tvs from '../Images/tvs.png';
import vertiv from '../Images/vertiv.jpg';
import amararaja from '../Images/amararaja.jpg';
import testImage from '../Images/testing.jpg';
import amralogo from '../Images/amralogo.png';
import ama1 from '../Images/amarajatran/3.1.png';
import ama2 from '../Images/amarajatran/3.2.png';
import ama3 from '../Images/amarajatran/3.3.png';
import ama4 from '../Images/amarajatran/3.4.png';
import ama5 from '../Images/amarajatran/3.5.png';
import ama6 from '../Images/amarajatran/3.6.png';
import ama7 from '../Images/amarajatran/3.7.png';
import ama8 from '../Images/amarajatran/3.8.png';
import ama9 from '../Images/amarajatran/3.9.png';
import ama10 from '../Images/amarajatran/3.91.png';

import ver1 from '../Images/vertivtran/4.1.png';
import ver2 from '../Images/vertivtran/4.2.png';
import ver3 from '../Images/vertivtran/4.3.png';

import './Company.css';
function CompanyDefault() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [indexHp, setIndexHp] = useState(0);

    const handleSelectHp = (selectedIndex, e) => {
        setIndexHp(selectedIndex);
    };

    const [indexAmararaja, setIndexAmararaja] = useState(0);

    const handleSelectAmararaja = (selectedIndex, e) => {
        setIndexAmararaja(selectedIndex);
    };
    const [indexVertiv, setIndexVertiv] = useState(0);

    const handleSelectVertiv = (selectedIndex, e) => {
        setIndexVertiv(selectedIndex);
    };
    const [indexTvs, setIndexTvs] = useState(0);

    const handleSelectTvs = (selectedIndex, e) => {
        setIndexTvs(selectedIndex);
    };

    return (
        <div className=' d-block d-md-none'>
            <div className='container text-center mx-auto '>
                <h2
                    className='text-3d mb-5'
                    data-text='One Place For All Your '
                    style={{
                        fontWeight: 900,
                    }}>
                    We Value Innovation,
                    <br />
                    Quality & Trust
                </h2>
            </div>

            <div className='row mb-5 '>
                <div className='col-md-0 col-lg-1'></div>
                <div className='col-md-12 col-lg-10 shabox px-0'>
                    <div
                        className='row'
                        style={{
                            backgroundImage: `linear-gradient(to right bottom,rgba(255,255,255,0.5),rgba(255,255,255,0.75)),url(${testImage})`,
                            backgroundSize: 'cover',
                            backgroundAttachment: 'fixed',
                        }}>
                        <div className='col-md-6 col-lg-6  text-right '>
                            <Carousel fade={true}>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/c/0/c04894793.png)`,
                                        }}></div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/Y/5/Y5S54A-1_T1561014105.png)`,
                                        }}></div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://www.tvs-e.in/wp-content/uploads/2019/12/RP-3220-STAR.png)`,
                                        }}></div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/G/1/G1W39A-1_T1539229999.png)`,
                                        }}></div>
                                </Carousel.Item>

                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/Z/6/Z6Z13A-1_T1539232253.png
)`,
                                        }}></div>
                                </Carousel.Item>

                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/L/2/L2759A-1_T1539228482.png)`,
                                        }}></div>
                                </Carousel.Item>
                            </Carousel>
                            <div className='container text-center '>
                                <div className='row text-center align-items-center'>
                                    <div className='col text-center'>
                                        <Link to='/details/others/allprinters/allprinters'>
                                            <h5
                                                style={{
                                                    color: 'black',
                                                    fontWeight: '800',
                                                    cursor: 'pointer',
                                                }}>
                                                Printers
                                            </h5>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mb-5 '>
                <div className='col-md-0 col-lg-1'></div>
                <div className='col-md-12 col-lg-10 shabox px-0'>
                    <div
                        className='row'
                        style={{
                            backgroundImage: `linear-gradient(to right bottom,rgba(255,255,255,0.5),rgba(255,255,255,0.75)),url(${testImage})`,
                            backgroundSize: 'cover',
                            backgroundAttachment: 'fixed',
                        }}>
                        <div className='col-md-6 col-lg-6  text-left '>
                            <Carousel fade={true}>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/h/p/hp_682_tri-color_3ym76aa_front_view.png)`,
                                        }}></div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/L/0/L0S54AA-1_T1539225732.png)`,
                                        }}></div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/C/E/CE278AF-1_T1539224510.png)`,
                                        }}></div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/W/1/W1103AD-1_T1564128832.png)`,
                                        }}></div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/X/4/X4E78AA-1_T1539227928.png)`,
                                        }}></div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/1/V/1VV21AA-1_T1563523556.png)`,
                                        }}></div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/C/E/CE506A-1_T1539224566.png
)`,
                                        }}></div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/S/U/SU825A-1_T1550112858.png)`,
                                        }}></div>
                                </Carousel.Item>
                            </Carousel>
                            <div className='container text-center '>
                                <div className='row text-center align-items-center'>
                                    <div className='col text-center'>
                                        <Link to='/details/others/allcartridges/allcartridges'>
                                            <h5
                                                style={{
                                                    color: 'black',
                                                    fontWeight: '800',
                                                    cursor: 'pointer',
                                                }}>
                                                Cartridges
                                            </h5>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mb-5'>
                <div className='col-md-0 col-lg-1'></div>
                <div className='col-md-12 col-lg-10 shabox px-0'>
                    <div
                        className='row'
                        style={{
                            backgroundImage: `linear-gradient(to right bottom,rgba(255,255,255,0.5),rgba(255,255,255,0.75)),url(${hpbackground})`,
                            backgroundSize: 'cover',
                            backgroundAttachment: 'fixed',
                        }}>
                        <div className='col-md-4 col-lg-4 text-center my-auto '></div>

                        <div className='col-md-8 col-lg-8  text-right '>
                            <Carousel
                                activeIndex={indexHp}
                                onSelect={handleSelectHp}
                                fade={true}>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/2/V/2VN99AA-1_T1542593902.png)`,
                                        }}>
                                        <Link to='/category/hp'>
                                            <img
                                                alt='logo'
                                                src={hplogo}
                                                style={{
                                                    height: '75px',
                                                    marginTop: '10px',
                                                    zIndex: '10',
                                                }}
                                            />
                                        </Link>
                                    </div>

                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/service/hp/ACCESSORIES/hp_accessories'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Accesories
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/1/f/1fh47a7_1.png)`,
                                        }}>
                                        <Link to='/category/hp'>
                                            <img
                                                alt='logo'
                                                src={hplogo}
                                                style={{
                                                    height: '75px',
                                                    marginTop: '10px',
                                                    zIndex: '10',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center'>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/service/hp/MONITORS/hp_monitors'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Monitors
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/1/4/14-dh1008tu_1.png)`,
                                        }}>
                                        <Link to='/category/hp'>
                                            <img
                                                alt='logo'
                                                src={hplogo}
                                                style={{
                                                    height: '75px',
                                                    marginTop: '10px',
                                                    zIndex: '10',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center'>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/service/hp/PERSONAL_LAPTOPS/hp_personal_laptops'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Personal Laptops
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/C/E/CE655A-1_T1539224582.png)`,
                                        }}>
                                        <Link to='/category/hp'>
                                            <img
                                                alt='logo'
                                                src={hplogo}
                                                style={{
                                                    height: '75px',
                                                    marginTop: '10px',
                                                    zIndex: '10',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center'>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/service/hp/PRINTERS/hp_printers'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Printers
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/L/2/L2741A-1_T1539225754.png)`,
                                        }}>
                                        <Link to='/category/hp'>
                                            <img
                                                alt='logo'
                                                src={hplogo}
                                                style={{
                                                    height: '75px',
                                                    marginTop: '10px',
                                                    zIndex: '10',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center'>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='products/hp/hp_printers/hp_printers_scanners'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Scanners
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/5/Q/5QC71AA-1_T1593164604.png)`,
                                        }}>
                                        <Link to='/category/hp'>
                                            <img
                                                alt='logo'
                                                src={hplogo}
                                                style={{
                                                    height: '75px',
                                                    marginTop: '10px',
                                                    zIndex: '10',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center'>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/service/hp/DESKTOPS/hp_desktops'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Desktops
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(https://in-media.apjonlinecdn.com/catalog/product/cache/314dec89b3219941707ad62ccc90e585/9/K/9KW89PA-1_T1586423594.png)`,
                                        }}>
                                        <Link to='/category/hp'>
                                            <img
                                                alt='logo'
                                                src={hplogo}
                                                style={{
                                                    height: '75px',
                                                    marginTop: '10px',
                                                    zIndex: '10',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center'>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/service/hp/BUSINESS_LAPTOPS/hp_bussiness_laptop'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Business Laptops
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>

            {/* Amararaja */}
            <div className='row mb-5 '>
                <div className='col-md-0 col-lg-1'></div>
                <div className='col-md-12 col-lg-10 shabox px-0'>
                    <div
                        className='row'
                        style={{
                            backgroundImage: `linear-gradient(to right bottom,rgba(255,255,255,0.5),rgba(255,255,255,0.75)),url(${amararaja})`,
                            backgroundSize: 'cover',
                            backgroundAttachment: 'fixed',
                        }}>
                        <div className='col-md-8 col-lg-8  text-left '>
                            <Carousel
                                activeIndex={indexAmararaja}
                                onSelect={handleSelectAmararaja}
                                fade={true}>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(${ama1})`,
                                        }}>
                                        <Link to='/products/amraraja/amraraja/amraraja'>
                                            <img
                                                alt='logo'
                                                src={amralogo}
                                                style={{
                                                    height: '80px',
                                                    marginTop: '10px',
                                                    zIndex: '10',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/products/amraraja/amraraja/amraraja'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Amaron Quanta-HWS
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(${ama2})`,
                                        }}>
                                        <Link to='/products/amraraja/amraraja/amraraja'>
                                            <img
                                                alt='logo'
                                                src={amralogo}
                                                style={{
                                                    height: '80px',
                                                    marginTop: '10px',
                                                    zIndex: '10',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/products/amraraja/amraraja/amraraja'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Amaron Brute
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(${ama3})`,
                                        }}>
                                        <Link to='/products/amraraja/amraraja/amraraja'>
                                            <img
                                                alt='logo'
                                                src={amralogo}
                                                style={{
                                                    height: '80px',
                                                    marginTop: '10px',
                                                    zIndex: '10',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/products/amraraja/amraraja/amraraja'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Amaron Volt
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(${ama4})`,
                                        }}>
                                        <Link to='/products/amraraja/amraraja/amraraja'>
                                            <img
                                                alt='logo'
                                                src={amralogo}
                                                style={{
                                                    height: '80px',
                                                    marginTop: '10px',
                                                    zIndex: '10',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/products/amraraja/amraraja/amraraja'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        PowerStack
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(${ama5})`,
                                        }}>
                                        <Link to='/products/amraraja/amraraja/amraraja'>
                                            <img
                                                alt='logo'
                                                src={amralogo}
                                                style={{
                                                    height: '80px',
                                                    marginTop: '10px',
                                                    zIndex: '10',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/products/amraraja/amraraja/amraraja'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Genpro
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(${ama6})`,
                                        }}>
                                        <Link to='/products/amraraja/amraraja/amraraja'>
                                            <img
                                                alt='logo'
                                                src={amralogo}
                                                style={{
                                                    height: '80px',
                                                    marginTop: '10px',
                                                    zIndex: '10',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/products/amraraja/amraraja/amraraja'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Amaron Solar
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(${ama7})`,
                                        }}>
                                        <Link to='/products/amraraja/amraraja/amraraja'>
                                            <img
                                                alt='logo'
                                                src={amralogo}
                                                style={{
                                                    height: '80px',
                                                    marginTop: '10px',
                                                    zIndex: '10',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/products/amraraja/amraraja/amraraja'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Amaron Sleek
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(${ama8})`,
                                        }}>
                                        <Link to='/products/amraraja/amraraja/amraraja'>
                                            <img
                                                alt='logo'
                                                src={amralogo}
                                                style={{
                                                    height: '80px',
                                                    marginTop: '10px',
                                                    zIndex: '10',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/products/amraraja/amraraja/amraraja'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Amaron Quanta
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>

                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(${ama9})`,
                                        }}>
                                        <Link to='/products/amraraja/amraraja/amraraja'>
                                            <img
                                                alt='logo'
                                                src={amralogo}
                                                style={{
                                                    height: '80px',
                                                    marginTop: '10px',
                                                    zIndex: '10',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/products/amraraja/amraraja/amraraja'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Amaron Quanta XEL
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main '
                                        style={{
                                            backgroundImage: `url(${ama10})`,
                                        }}>
                                        <Link to='/products/amraraja/amraraja/amraraja'>
                                            <img
                                                alt='logo'
                                                src={amralogo}
                                                style={{
                                                    height: '80px',
                                                    marginTop: '10px',
                                                    zIndex: '10',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/products/amraraja/amraraja/amraraja'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Amaron QuantaHUPS
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>

            {/* Vertiv */}
            <div className='row mb-5 '>
                <div className='col-md-0 col-lg-1'></div>
                <div className='col-md-12 col-lg-10 shabox px-0'>
                    <div
                        className='row'
                        style={{
                            backgroundImage: `linear-gradient(to right bottom,rgba(255,255,255,0.5),rgba(255,255,255,0.75)),url(${vertiv})`,
                            backgroundSize: 'cover',
                            backgroundAttachment: 'fixed',
                        }}>
                        <div className='col-md-8 col-lg-8 text-right '>
                            <Carousel
                                activeIndex={indexVertiv}
                                onSelect={handleSelectVertiv}
                                fade={true}>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(${ver1})`,
                                        }}>
                                        <Link to='/category/vertiv'>
                                            <img
                                                alt='logo'
                                                src={vmlogo}
                                                style={{
                                                    height: '70px',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/service/vertiv/CRITICAL_POWER/critical_power'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Critical Power
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(${ver2})`,
                                        }}>
                                        <Link to='/category/vertiv'>
                                            <img
                                                alt='logo'
                                                src={vmlogo}
                                                style={{
                                                    height: '70px',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/service/vertiv/THERMAL_MANAGEMENT/thermal_management'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Thermal Management
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(${ver3})`,
                                        }}>
                                        <Link to='/category/vertiv'>
                                            <img
                                                alt='logo'
                                                src={vmlogo}
                                                style={{
                                                    height: '70px',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/service/vertiv/RACKS_&_ENCLOSURES/racks_and_enclosures'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Rack & Enclosures
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>

            {/* Tvs */}
            <div className='row mb-5 '>
                <div className='col-md-0 col-lg-1'></div>
                <div className='col-md-12 col-lg-10 shabox px-0'>
                    <div
                        className='row'
                        style={{
                            backgroundImage: `linear-gradient(to right bottom,rgba(255,255,255,0.5),rgba(255,255,255,0.75)),url(${tvs})`,
                            backgroundSize: 'cover',
                        }}>
                        <div className='col-md-8 col-lg-7  text-left '>
                            <Carousel
                                activeIndex={indexTvs}
                                onSelect={handleSelectTvs}
                                fade={true}>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(https://www.tvs-e.in/wp-content/uploads/2019/12/BS-L100.png)`,
                                        }}>
                                        <Link to='/category/tvs'>
                                            <img
                                                alt='logo'
                                                src={tvslogo}
                                                style={{
                                                    height: '75px',
                                                    marginTop: '10px',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/products/tvs/barcode_scanners/barcode_scanners'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Barcode Scanners
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(https://www.tvs-e.in/wp-content/uploads/2019/12/PT3124.png)`,
                                        }}>
                                        <Link to='/category/tvs'>
                                            <img
                                                alt='logo'
                                                src={tvslogo}
                                                style={{
                                                    height: '75px',
                                                    marginTop: '10px',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/products/tvs/cashregisters/cashregisters'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        E-Cash Registers
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(https://www.tvs-e.in/wp-content/uploads/2020/01/Cash-Drawer-5-notes-768x541.png)`,
                                        }}>
                                        <Link to='/category/tvs'>
                                            <img
                                                alt='logo'
                                                src={tvslogo}
                                                style={{
                                                    height: '75px',
                                                    marginTop: '10px',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/products/tvs/cash_drawers/cash_drawers'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Cash Drawers
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(https://www.tvs-e.in/wp-content/uploads/2020/02/DG-150S-300x196.png)`,
                                        }}>
                                        <Link to='/category/tvs'>
                                            <img
                                                alt='logo'
                                                src={tvslogo}
                                                style={{
                                                    height: '75px',
                                                    marginTop: '10px',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/products/tvs/oem_models/oem_models'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        OEM Models
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(https://www.tvs-e.in/wp-content/uploads/2019/12/RP-45-SHOPPE.png)`,
                                        }}>
                                        <Link to='/category/tvs'>
                                            <img
                                                alt='logo'
                                                src={tvslogo}
                                                style={{
                                                    height: '75px',
                                                    marginTop: '10px',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/products/tvs/pos_dmp/pos_dmp'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        POS DMP
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(https://www.tvs-e.in/wp-content/uploads/2019/12/MSP-250-STAR-300x203.png)`,
                                        }}>
                                        <Link to='/category/tvs'>
                                            <img
                                                alt='logo'
                                                src={tvslogo}
                                                style={{
                                                    height: '75px',
                                                    marginTop: '10px',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/service/tvs/PRINTERS/tvs_printers'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Printers
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(https://www.tvs-e.in/wp-content/uploads/2020/03/Asset-134mdpi-300x283.png)`,
                                        }}>
                                        <Link to='/category/tvs'>
                                            <img
                                                alt='logo'
                                                src={tvslogo}
                                                style={{
                                                    height: '75px',
                                                    marginTop: '10px',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/products/tvs/cash_counters/cash_counters'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Cash Counters
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(https://www.tvs-e.in/wp-content/uploads/2019/12/gold-300x147.png)`,
                                        }}>
                                        <Link to='/category/tvs'>
                                            <img
                                                alt='logo'
                                                src={tvslogo}
                                                style={{
                                                    height: '75px',
                                                    marginTop: '10px',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/products/tvs/keyboards/keyboards'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        Keyboards & Mice
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className='hp_main'
                                        style={{
                                            backgroundImage: `url(https://www.tvs-e.in/wp-content/uploads/2019/11/C4515-STAR-FEATURES-300x203.png)`,
                                        }}>
                                        <Link to='/category/tvs'>
                                            <img
                                                alt='logo'
                                                src={tvslogo}
                                                style={{
                                                    height: '75px',
                                                    marginTop: '10px',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className='container text-center '>
                                        <div className='row text-center align-items-center'>
                                            <div className='col text-center'>
                                                <Link to='/products/tvs/touch_pos_systems/touch_pos_systems'>
                                                    <h5
                                                        style={{
                                                            color: 'black',
                                                            fontWeight: '800',
                                                            cursor: 'pointer',
                                                        }}>
                                                        POS Systems
                                                    </h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompanyDefault;
