import React from 'react';
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer';

import category from '../../../JSON/main.json';
import CategoryOther from '../../CategoryOther/CategoryOther';

function CategoryOtherScreen() {
    return (
        <div>
            <Navbar />
            <CategoryOther category={category} />
            <Footer />
        </div>
    );
}

export default CategoryOtherScreen;
